import React, { useState, useEffect } from "react";

import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import blankImg from "../../../assets/images/users/blank_profile.svg";
import "react-phone-number-input/style.css";
import DateRangePicker from "../../../components/DateRangePicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import cloudOutline from "../../../assets/images/cloud-outline.svg";
import getAge from "../../../helpers/getAge";

import ApiService from "../../../config/restapi";
import s3apis from "../../../helpers/s3apis";
import Common from "../../../helpers/common";

const EditProfileModal = (props) => {
  console.log(props);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [country, setCountry] = useState("IN");

  const [fname, setFName] = useState();
  const [designation, setDesignation] = useState();
  const [lname, setLName] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [localcountry, setLocalCountry] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipCode] = useState();
  const [lnkurl, setLnkUrl] = useState();
  const [dob, setDob] = useState();
  const [age, setAge] = useState();
  const [profilepic, setProfilepic] = useState(null);
  const [isdCode, setIsdCode] = useState();
  const [showError, setShowError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("Select");
  const [selectedDistrict, setSelectedDistrict] = useState("Select");
  const countries = [
    { name: "Select", districts: ["Select"] },
    {
      name: "India",
      districts: [
        "Select",
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Hyderabad",
        "Ahmedabad",
        "Chennai",
        "Kolkata",
        "Surat",
        "Pune",
        "Jaipur",
        "Lucknow",
        "Kanpur",
        "Nagpur",
        "Indore",
        "Thane",
        "Bhopal",
        "Visakhapatnam",
        "Pimpri-Chinchwad",
        "Patna",
        "Vadodara",
        "Ghaziabad",
        "Ludhiana",
        "Agra",
        "Nashik",
        "Faridabad",
        "Meerut",
        "Rajkot",
        "Kalyan-Dombivali",
        "Vasai-Vira",
        "Varanasi",
        "Srinagar",
        "Aurangabad",
        "Dhanbad",
        "Amritsar",
        "NaviMumbai",
        "Allahabad",
        "Ranchi",
        "Howrah",
        "Coimbatore",
        "Jabalpur",
        "Gwalior",
        "Vijayawada",
        "Jodhpur",
        "Madurai",
        "Raipur",
        "Kota",
      ],
    },
    {
      name: "US",
      districts: [
        "Select",
        "Aberdeen",
        "Abilene",
        "Akron",
        "Albany",
        "Albuquerque",
        "Alexandria",
        "Allentown",
        "Amarillo",
        "Anaheim",
        "Anchorage",
        "Ann Arbor",
        "Antioch",
        "Apple Valley",
        "Appleton",
        "Arlington",
        "Arvada",
        "Asheville",
        "Athens",
        "Atlanta",
        "Atlantic City",
        "Augusta",
        "Aurora",
        "Austin",
        "Bakersfield",
        "Baltimore",
        "Barnstable",
        "Baton Rouge",
        "Beaumont",
        "Bel Air",
        "Bellevue",
        "Berkeley",
        "Bethlehem",
        "Billings",
        "Birmingham",
        "Bloomington",
        "Boise",
        "Boise City",
        "Bonita Springs",
        "Boston",
        "Boulder",
        "Bradenton",
        "Bremerton",
        "Bridgeport",
        "Brighton",
        "Brownsville",
        "Bryan",
        "Buffalo",
        "Burbank",
        "Burlington",
        "Cambridge",
        "Canton",
        "Cape Coral",
        "Carrollton",
        "Cary",
        "Cathedral City",
        "Cedar Rapids",
        "Champaign",
        "Chandler",
        "Charleston",
        "Charlotte",
        "Chattanooga",
        "Chesapeake",
        "Chicago",
        "Chula Vista",
        "Cincinnati",
        "Clarke County",
        "Clarksville",
        "Clearwater",
        "Cleveland",
        "College Station",
        "Colorado Springs",
        "Columbia",
        "Columbus",
        "Concord",
        "Coral Springs",
        "Corona",
        "Corpus Christi",
        "Costa Mesa",
        "Dallas",
        "Daly City",
        "Danbury",
        "Davenport",
        "Davidson County",
        "Dayton",
        "Daytona Beach",
        "Deltona",
        "Denton",
        "Denver",
        "Des Moines",
        "Detroit",
        "Downey",
        "Duluth",
        "Durham",
        "El Monte",
        "El Paso",
        "Elizabeth",
        "Elk Grove",
        "Elkhart",
        "Erie",
        "Escondido",
        "Eugene",
        "Evansville",
        "Fairfield",
        "Fargo",
        "Fayetteville",
        "Fitchburg",
        "Flint",
        "Fontana",
        "Fort Collins",
        "Fort Lauderdale",
        "Fort Smith",
        "Fort Walton Beach",
        "Fort Wayne",
        "Fort Worth",
        "Frederick",
        "Fremont",
        "Fresno",
        "Fullerton",
        "Gainesville",
        "Garden Grove",
        "Garland",
        "Gastonia",
        "Gilbert",
        "Glendale",
        "Grand Prairie",
        "Grand Rapids",
        "Grayslake",
        "Green Bay",
        "GreenBay",
        "Greensboro",
        "Greenville",
        "Gulfport-Biloxi",
        "Hagerstown",
        "Hampton",
        "Harlingen",
        "Harrisburg",
        "Hartford",
        "Havre de Grace",
        "Hayward",
        "Hemet",
        "Henderson",
        "Hesperia",
        "Hialeah",
        "Hickory",
        "High Point",
        "Hollywood",
        "Honolulu",
        "Houma",
        "Houston",
        "Howell",
        "Huntington",
        "Huntington Beach",
        "Huntsville",
        "Independence",
        "Indianapolis",
        "Inglewood",
        "Irvine",
        "Irving",
        "Jackson",
        "Jacksonville",
        "Jefferson",
        "Jersey City",
        "Johnson City",
        "Joliet",
        "Kailua",
        "Kalamazoo",
        "Kaneohe",
        "Kansas City",
        "Kennewick",
        "Kenosha",
        "Killeen",
        "Kissimmee",
        "Knoxville",
        "Lacey",
        "Lafayette",
        "Lake Charles",
        "Lakeland",
        "Lakewood",
        "Lancaster",
        "Lansing",
        "Laredo",
        "Las Cruces",
        "Las Vegas",
        "Layton",
        "Leominster",
        "Lewisville",
        "Lexington",
        "Lincoln",
        "Little Rock",
        "Long Beach",
        "Lorain",
        "Los Angeles",
        "Louisville",
        "Lowell",
        "Lubbock",
        "Macon",
        "Madison",
        "Manchester",
        "Marina",
        "Marysville",
        "McAllen",
        "McHenry",
        "Medford",
        "Melbourne",
        "Memphis",
        "Merced",
        "Mesa",
        "Mesquite",
        "Miami",
        "Milwaukee",
        "Minneapolis",
        "Miramar",
        "Mission Viejo",
        "Mobile",
        "Modesto",
        "Monroe",
        "Monterey",
        "Montgomery",
        "Moreno Valley",
        "Murfreesboro",
        "Murrieta",
        "Muskegon",
        "Myrtle Beach",
        "Naperville",
        "Naples",
        "Nashua",
        "Nashville",
        "New Bedford",
        "New Haven",
        "New London",
        "New Orleans",
        "New York",
        "New York City",
        "Newark",
        "Newburgh",
        "Newport News",
        "Norfolk",
        "Normal",
        "Norman",
        "North Charleston",
        "North Las Vegas",
        "North Port",
        "Norwalk",
        "Norwich",
        "Oakland",
        "Ocala",
        "Oceanside",
        "Odessa",
        "Ogden",
        "Oklahoma City",
        "Olathe",
        "Olympia",
        "Omaha",
        "Ontario",
        "Orange",
        "Orem",
        "Orlando",
        "Overland Park",
        "Oxnard",
        "Palm Bay",
        "Palm Springs",
        "Palmdale",
        "Panama City",
        "Pasadena",
        "Paterson",
        "Pembroke Pines",
        "Pensacola",
        "Peoria",
        "Philadelphia",
        "Phoenix",
        "Pittsburgh",
        "Plano",
        "Pomona",
        "Pompano Beach",
        "Port Arthur",
        "Port Orange",
        "Port Saint Lucie",
        "Port St. Lucie",
        "Portland",
        "Portsmouth",
        "Poughkeepsie",
        "Providence",
        "Provo",
        "Pueblo",
        "Punta Gorda",
        "Racine",
        "Raleigh",
        "Rancho Cucamonga",
        "Reading",
        "Redding",
        "Reno",
        "Richland",
        "Richmond",
        "Richmond County",
        "Riverside",
        "Roanoke",
        "Rochester",
        "Rockford",
        "Roseville",
        "Round Lake Beach",
        "Sacramento",
        "Saginaw",
        "Saint Louis",
        "Saint Paul",
        "Saint Petersburg",
        "Salem",
        "Salinas",
        "Salt Lake City",
        "San Antonio",
        "San Bernardino",
        "San Buenaventura",
        "San Diego",
        "San Francisco",
        "San Jose",
        "Santa Ana",
        "Santa Barbara",
        "Santa Clara",
        "Santa Clarita",
        "Santa Cruz",
        "Santa Maria",
        "Santa Rosa",
        "Sarasota",
        "Savannah",
        "Scottsdale",
        "Scranton",
        "Seaside",
        "Seattle",
        "Sebastian",
        "Shreveport",
        "Simi Valley",
        "Sioux City",
        "Sioux Falls",
        "South Bend",
        "South Lyon",
        "Spartanburg",
        "Spokane",
        "Springdale",
        "Springfield",
        "St. Louis",
        "St. Paul",
        "St. Petersburg",
        "Stamford",
        "Sterling Heights",
        "Stockton",
        "Sunnyvale",
        "Syracuse",
        "Tacoma",
        "Tallahassee",
        "Tampa",
        "Temecula",
        "Tempe",
        "Thornton",
        "Thousand Oaks",
        "Toledo",
        "Topeka",
        "Torrance",
        "Trenton",
        "Tucson",
        "Tulsa",
        "Tuscaloosa",
        "Tyler",
        "Utica",
        "Vallejo",
        "Vancouver",
        "Vero Beach",
        "Victorville",
        "Virginia Beach",
        "Visalia",
        "Waco",
        "Warren",
        "Washington",
        "Waterbury",
        "Waterloo",
        "West Covina",
        "West Valley City",
        "Westminster",
        "Wichita",
        "Wilmington",
        "Winston",
        "Winter Haven",
        "Worcester",
        "Yakima",
        "Yonkers",
        "York",
        "Youngstown",
      ],
    },
  ];
  const [districts, setDistricts] = useState(["Select"]);

  useEffect(() => {
    if (props.editedData) {
      setProfilepic(props.editedData.image_url);
      setFName(props.editedData.name);
      setDesignation(props.editedData.designation);
      setEmail(props.editedData.email);
      setMobileNumber(props.editedData.phone);
      setCity(props.editedData.city);
      setLnkUrl(props.editedData.linked_in_url);
      setLocalCountry(props.editedData.country);
      setDob(
        props.editedData.date_of_birth
          ? new Date(props.editedData.date_of_birth)
          : ""
      );
      setAge(props.editedData.age);
      setIsdCode(props.editedData.isd_code);
      setSelectedCountry(props.editedData.country);
      setSelectedDistrict(props.editedData.city);
      setZipCode(props.editedData.zipcode);
    }
  }, [props.editedData, props.modal_center]);

  const changeCountry = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedDistrict("Select");
    if (event.target.value === "Select") {
      OnchangeHandler("", "localcountry");
      OnchangeHandler("", "city");
    } else {
      OnchangeHandler(event.target.value, "localcountry");
      OnchangeHandler("", "city");
    }
  };

  const changeDistrict = (event) => {
    if (event.target.value === "Select") {
      OnchangeHandler("", "city");
    } else {
      OnchangeHandler(event.target.value, "city");
    }
    setSelectedDistrict(event.target.value);
  };
  // function handleUpload(file) {
  //   try {
  //     if (!file) {
  //       throw new Error("Select a file first!");
  //     }
  //     const formData = new FormData();
  //     formData.append("file",file[0]);
  //     setProfilepic(formData);
  //     handleAcceptedFiles(file);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (
      ext &&
      (ext.toLowerCase() === "jpeg" ||
        ext.toLowerCase() === "png" ||
        ext.toLowerCase() === "gif" ||
        ext.toLowerCase() === "jpg")
    ) {
      return true;
    }
    return false;
  }

  // onChange={(e) =>
  //   handleUpload(
  //     e.target.files)
  // }

  const handleAcceptedFiles = async (files) => {
    if (validateFileExtention(files)) {
      console.log(files[0].name);

      var ext = files[0].name.split(".").pop();
      if (files[0].size < 5242880) {
        const url = await s3apis.upload("profilePic", ext, files[0]);
        OnchangeHandler(url, "image_url");
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
      }
    } else {
      toastr.error("Please Upload Valid Photo", "", { timeOut: 2000 });
    }
  };

  function clearData() {}

  const removeProfilePic = () => {
    setselectedFiles("");
    setProfilepic(null);
  };

  const OnchangeHandler = (value, type) => {
    switch (type) {
      case "fname":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setFName(value);
        break;
      case "designation":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setDesignation(value);
        break;
      case "lname":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setLName(value);
        break;
      case "email":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setEmail(value);
        break;
      case "mobileNumber":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setMobileNumber(value);
        break;
      case "localcountry":
        setLocalCountry(value);
        break;
      case "city":
        setCity(value);
        break;
      case "zipcode":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setZipCode(value);
        break;
      case "lnkurl":
        setLnkUrl(value);
        break;
      case "dob":
        setDob(value);
        setAge(getAge(value));
        break;
      case "age":
        setAge(value);
        break;
      case "isd_code":
        setIsdCode(value);
        break;
      case "image_url":
        setProfilepic(value);
        break;
      default:
        break;
    }
  };
  function checkname(name, s) {
    if (name === "" || !name) {
      return { message: `${s} should not be blank`, valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: `Please enter a valid ${s}`, valid: false };
    }
  }
  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }
  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkCountry(value) {
    if (value === "select" || !value) {
      return { message: "Select a country", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkCity(value) {
    if (value === "Select" || !value) {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkzipcode(value) {
    if (/^[0-9]+$/.test(value) && value.length > 2) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid zipcode", valid: false };
  }
  function handleSavedChanges() {
    if (
      !checkname(fname, "name").valid ||
      !checkEmail(email).valid ||
      !checkmobileNumber(mobileNumber).valid
    ) {
      setShowError(true);
      return;
    }
    props.onModalSubmit(
      {
        name: fname,
        designation,
        email,
        age,
        phone: mobileNumber,
        country: localcountry,
        city,
        zipcode,
        linked_in_url: lnkurl,
        id: props.editedData.id,
        date_of_birth: dob,
        image_url: profilepic,
        isd_code: isdCode,
      },
      "edit"
    );
    clearData();
  }
  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      size="lg"
      className="edit-profile-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Edit Profile
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              {/* <Label>Profile Photo</Label> */}
              <div className="d-flex">
                <div className="profile-logo-container" style={{height:"3rem",padding:"0px"}}>
                  <div className="profile-logo-content">
                    <img
                      src={profilepic ? profilepic : blankImg}
                      alt=""
                      className="avatar-sm"
                      style={{borderRadius:"50%"}}
                    />
                  </div>
                </div>
                <div className="upload-section">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="font-size-12">
                            <button
                              className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                              type="button"
                            >
                              <img src={cloudOutline} alt="cloud" />{" "}
                              <span className="pl-1">UPLOAD PHOTO</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {profilepic && (
                    <div className="pt-1 pl-1">
                      <button
                        className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                        type="button"
                        onClick={() => removeProfilePic()}
                      >
                        <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required" />
                        <span className="pl-1 label-required">
                          REMOVE PHOTO{" "}
                        </span>
                      </button>
                    </div>
                  )}
                  File size limit: 5mb
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Full Name<span className="label-required"> * </span>
              </Label>
              <Input
                maxLength="40"
                type="text"
                value={fname || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "fname")}
                className="form-control"
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkname(fname, "Name").message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-designation-input1">Designation</Label>
              <Input
                maxLength="40"
                type="text"
                value={designation || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "designation")}
                className="form-control"
              />
            </FormGroup>
          </Col>
          {/* <Col lg="6">
                        <FormGroup className="text-left">
                            <Label for="basicpill-firstname-input1">Last Name </Label>
                            <Input type="text"value={lname || ''} onChange={(e)=>OnchangeHandler(e.target.value,'lname')} className="form-control"  />
                        </FormGroup>
                    </Col> */}
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="mb-4 text-left">
              <Label>
                D.O.B<span className="label-required"> * </span>
              </Label>
              <InputGroup>
                <DateRangePicker
                  propsref="birthcalendar"
                  proptype="birth"
                  propsid="dobpicker"
                  selected={dob || ""}
                  onChange={(val) => OnchangeHandler(val, "dob")}
                />
                <label htmlFor="dobpicker">
                  <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                </label>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="cust-age-width text-left">
              <Label for="basicpill-lastname-input2">Age</Label>
              <Input
                type="text"
                value={age || ""}
                readOnly
                onChange={(event) => {}}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Email Address<span className="label-required"> * </span>{" "}
              </Label>
              <Input
                type="email"
                value={email || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(e.target.value);
                  OnchangeHandler(e.target.value, "email");
                }}
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkEmail(email).message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Phone Number<span className="label-required"> * </span>{" "}
              </Label>
              <Row>
                <Col lg="3" className="pr-0">
                  <select
                    className="form-control phone-number-select"
                    value={isdCode || ""}
                    onChange={(event) => {
                      OnchangeHandler(event.target.value, "isd_code");
                    }}
                  >
                    {getCountries().map((country) => (
                      <option key={country} value={country}>
                        {country} {getCountryCallingCode(country)}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg="9">
                  {/* <PhoneInput
                    country={country ? country : 'IN'}
                    international
                    value={mobileNumber}
                    onChange={(v) => OnchangeHandler(v, 'mobileNumber')}
                    className="form-control phone-number-input"
                  /> */}
                  <Input
                    type="tel"
                    maxLength={10}
                    value={mobileNumber || ""}
                    onChange={(e) =>
                      OnchangeHandler(e.target.value, "mobileNumber")
                    }
                  />
                </Col>
                {showError && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {checkmobileNumber(mobileNumber).message}
                  </label>
                )}
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label>
                Country<span className="label-required"> * </span>
              </Label>
              <Input
                type="select"
                name="select"
                onChange={(event) => changeCountry(event)}
              >
                {countries.map((e, key) => {
                  return (
                    <option key={key} selected={selectedCountry === e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </Input>
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkCountry(selectedCountry).message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-lastname-input2">
                City/District<span className="label-required"> * </span>
              </Label>
              <Input
                type="select"
                onChange={(event) => changeDistrict(event)}
                name="select"
                id="exampleCity"
              >
                {countries.find((i) => i.name === selectedCountry) &&
                  countries
                    .find((i) => i.name === selectedCountry)
                    .districts.map((e, key) => {
                      return (
                        <option key={key} selected={selectedDistrict === e}>
                          {e}
                        </option>
                      );
                    })}
              </Input>
              <label style={{ "margin-bottom": "0", color: "red" }}>
                {checkCity(selectedDistrict).message}
              </label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                ZIP Code<span className="label-required"> * </span>{" "}
              </Label>
              <Input
                maxLength="6"
                type="text"
                value={zipcode || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "zipcode")}
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkzipcode(zipcode).message}
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Linked In Profile URL
              </Label>
              <Input
                type="text"
                placeholder="https://www,linkedin.com/in/yourURL"
                value={lnkurl || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "lnkurl")}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-cancel btn-lg waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-light-green btn-lg waves-effect waves-light"
          onClick={() => {
            handleSavedChanges();
          }}
        >
          Save Changes{" "}
        </button>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
