import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import AboutYourselfWizard from './AboutYourselfWizard';
import UploadFile from '../../../components/UploadFile';
import logo from '../../../assets/images/Zunavish_Logo_SVG.svg';
import { withRouter } from 'react-router-dom';
import { getAllUserData } from "../../../redux/actions/profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApiService from '../../../config/restapi';
 
const AboutYourself = (props) =>{
   const [isManualDetailSection, setIsManualDetailSection] = useState(false)
   const [isCompleteProfile, setIsCompleteProfile] = useState(false);
    function fillDetailsManual(){
        setIsManualDetailSection(true);
    }
    function uploadResumeCallback(f){
        const formData = new FormData();
        formData.append("file", f);
        ApiService.uploadJobResume(formData).then((res) => {
            if (res && res.data) {
                ApiService.editProfileData({resume_url:res.data.data.Location}).then((res) => {
                });

            }});
    }

    function removeResumeFile() {
        if(props.profile.resume_url) {
            ApiService.editProfileData({resume_url:""}).then((res) => {
            }); 
        }
    }

    function onCompleteAboutYourselfCallBack(){
        setIsCompleteProfile(true);
        props.history.push("/studentDashboard");
    }

    useEffect(() => {
        props.getAllUserData();
      }, []);

    return(
        <React.Fragment>
            <div className="page-content mt-0 p-0 grey-bg">
                <div className='logo-fixed'>
                    <img src={logo} alt='logo'></img>
                </div>
                <Container className="about-page-auth" fluid>
                    <Row>
                        <Col xs="12" xl="3" sm="3"></Col>
                        <Col xs="12" xl="6" sm="6" className="text-center">
                            <h5 className="mb-0 font-size-27">Welcome, {props.profile && props.profile.name} </h5>
                            <div className="mt-3">
                                {!isManualDetailSection ? <p className="pb-3 font-size-16"> Upload your resume and make the process of filling details easy</p> : 
                                    (!isCompleteProfile ? <p className="pb-3 font-size-16"> Complete your profile and start exploring jobs that suit you the best </p> :
                                    <p className="pb-3 font-size-11"> Complete your profile and start exploring jobs that suit you the best </p>) }
                            </div>
                                {!isManualDetailSection ? <UploadFile
                                    title="Upload your resume and make the process of filling details easy"
                                    uploadResumeCallback={(f)=>uploadResumeCallback(f)}
                                    removeResumeFile={()=>removeResumeFile()}
                                   
                                /> :<AboutYourselfWizard
                                    title="Complete your profile and get more opportunities to connect with people"
                                    onCompleteAboutYourselfCallBack={()=>onCompleteAboutYourselfCallBack()}
                                />
                            }
                            {!isManualDetailSection ?
                                <div className="text-center mt-3">
                                    <button
                                        className="waves-effect waves-light btn btn-outline-green font-size-16"
                                        type="submit"
                                        onClick={() => fillDetailsManual()}
                                    >
                                        I'll fill details manually
                                    </button>
                                </div> : ''
                            }
                        </Col>
                        <Col xs="12" xl="3" sm="3"></Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      profile : {...state.profile.user},
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllUserData }, dispatch);
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutYourself));