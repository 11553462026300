import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Collapse, Media } from "reactstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import blankImg from "../../../assets/images/user.png";
import phone_icon from "../../../assets/images/phone_icon.png";
import email_icon from "../../../assets/images/email_icon.png";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import company_img from "../../../assets/images/company.png";
import ApiService from "../../../config/restapi";
import timeSince from "../../../helpers/timeSinceFormatter.js";
import coursera_icon from "../../../assets/images/coursera_icon.png";
import site_icon from "../../../assets/images/site_icon.png";
import udemy_icon from "../../../assets/images/udemy-icon.svg";
import PhoneInput, { getCountries, getCountryCallingCode } from "react-phone-number-input/input";

// Redux
//import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import AddBackgroundVerificationModal from "./AddBackgroundVerificationModal";
import BackgroundSuccessModal from "./BackgroundSuccessModal";
import CandidatureModal from "../Profile/CandidatureModal";
import s3apis from "../../../helpers/s3apis";

const StudentDashboard = (props) => {
    const [col1, setcol1] = useState(true);
    const [modal_large, setmodal_large] = useState(false);
    const [bg_succes, setbg_succes] = useState(false);
    const [dashBoardData, setDashBoardData] = useState();
    const [activityList, setActivityList] = useState([]);
    const [candidature_modal, setCandidature_modal] = useState(false);
    const [loadingJobs, setLoadingJobs] = useState(false);
    const [loadedJobs, setLoadedJobs] = useState(false);
    function openCandidatureModal() {
        setCandidature_modal(!candidature_modal);
        removeBodyCss();
    }
    function updateCandidature(data) {
        ApiService.saveOnboardingPersonalDetails({ video_url: data }).then((res) => {
            if (res && res.status === 200 && res.data) {
                openCandidatureModal(false);
            }
        });
    }
    const handleVideo = async (file, type) => {
        try {
            if (!file) {
                throw new Error("Select a file first!");
            }
            const formData = new FormData();
            formData.append("file", file);
            ApiService.updateVideo(formData).then((res) => {
                if (res && res.status === 200 && res.data) {
                    getDashBoadData();
                }
            });
            // handle success
        } catch (error) {
            console.log(error);
        }
    };

    function tog_large() {
        setmodal_large(!modal_large);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function updateBgModalData() {
        tog_large(false);
    }
    function tog_bg() {
        setbg_succes(!bg_succes);
        removeBodyCss();
    }
    function bgSuccessSumbit() {
        tog_bg(false);
    }

    function FetchAllJobs(page) {
        setLoadingJobs(true);
        ApiService.fetchAllJobs(page).then((res) => {
            if (res && res.status === 200 && res.data) {
                setActivityList([...activityList, ...res.data.jobs]);
                setLoadingJobs(false);
                if (res.data.jobs.length === 0) {
                    setLoadedJobs(true);
                }
            }
        });
    }

    function toggleRow(index) {
        let tempArray = [...activityList];
        tempArray[index]["open"] = !tempArray[index]["open"];
        setActivityList([...tempArray]);
    }

    const handlephoto = async (file, type) => {
        try {
            if (!file) {
                throw new Error("Select a file first!");
            }
            var ext = file.name.split(".").pop();
            if (file.size < 5242880) {
                await s3apis.upload("profilePic", ext, file);
                getDashBoadData();
            } else {
                toastr.error("Select a file of size less than 5 MB", "", {
                    timeOut: 2000,
                });
            }
            // handle success
        } catch (error) {
            console.log(error);
        }
    };

    function getDashBoadData() {
        ApiService.getDashboardData().then((res) => {
            if (res && res.status === 200 && res.data) {
                if (res.data.user.is_profile_created) {
                    setDashBoardData({ ...res.data });
                    FetchAllJobs(activityList.length);
                } else {
                    props.history.push("/studentOnboarding");
                }
            }
        });
    }
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });
    const listInnerRef = useRef();

    const handleScroll = () => {
        console.log(window.innerHeight + document.documentElement.scrollTop);
        console.log(document.documentElement.offsetHeight);
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight &&
            !loadingJobs &&
            !loadedJobs
        ) {
            // TO SOMETHING HERE
            FetchAllJobs(activityList.length);
            console.log("Reached bottom");
        }
    };

    useEffect(() => {
        getDashBoadData();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content pro-padding">
                <Container className="profile-page" fluid>
                    <Row>
                        <Col xl="4">
                            <Card className="overflow-hidden">
                                <Row className="pt-3">
                                    <Col xs="12" className="d-flex justify-content-center align-items-center">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <img
                                                src={
                                                    dashBoardData && dashBoardData.user.image_url
                                                        ? dashBoardData.user.image_url
                                                        : blankImg
                                                }
                                                alt=""
                                                className="img-thumbnail rounded-circle"
                                            />
                                        </div>
                                        {/* <span className="right-pencil-section">
                                            <i className="bx bx-pencil font-size-18 cursor-pointer" onClick={()=> { openModal()}}></i>
                                        </span>   */}
                                    </Col>
                                </Row>
                                <CardBody className="pt-0 text-center">
                                    <Row className="border-bottom">
                                        <Col sm={12}>
                                            <p className="font-size-16 text-truncate mb-1 font-weight-light">
                                                Welcome, {dashBoardData && dashBoardData.user.name}!
                                            </p>
                                            <div className="f-13 text-center mt-3">
                                                <img className="mr-2" src={email_icon} alt="email" />
                                                <span className="mobile_fonts">
                                                    {dashBoardData && dashBoardData.user.email}
                                                </span>
                                                <div className="mb-3 pt-3">
                                                    <img className="mr-2" src={phone_icon} alt="phone" />
                                                    <span className="mr-1 mobile_fonts">
                                                        {dashBoardData &&
                                                            dashBoardData.user &&
                                                            dashBoardData.user.isd_code
                                                            ? "+" + getCountryCallingCode(dashBoardData.user.isd_code)
                                                            : ""}{" "}
                                                        {dashBoardData && dashBoardData.user && dashBoardData.user.phone
                                                            ? dashBoardData.user.phone
                                                            : "--"}
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="profile mt-2 mb-2 d-flex justify-content-between align-items-center">
                                        <span className="font-size-18">Profile</span>
                                        <span className="float-right" style={{ display: "flex" }}>
                                            <span style={{ width: "16px", height: "16px" }}>
                                                <CircularProgressbar
                                                    value={Math.min(
                                                        100,
                                                        Math.max(dashBoardData && dashBoardData.perCentComplete, 0)
                                                    )}
                                                    background={true}
                                                    styles={{
                                                        path: {
                                                            stroke: "#213d30",
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                            // Trail color
                                                            stroke: "transparent",
                                                        },
                                                        // Customize the text

                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                            fill: "#74b496",
                                                        },
                                                    }}
                                                />
                                            </span>{" "}
                                            <span className="f-12 ml-2">
                                                {
                                                    +Math.min(
                                                        100,
                                                        Math.max(dashBoardData && dashBoardData.perCentComplete, 0)
                                                    ).toFixed(2)
                                                }
                                                % complete
                                            </span>
                                        </span>
                                    </div>
                                    <div className="mb-2 d-flex justify-content-between">
                                        <span className="small-blurred-text font-size-12 mobile_fonts">
                                            Help recruiters recognise you
                                        </span>
                                        <span className="f-g f-12">
                                            <div>
                                                <input
                                                    type="file"
                                                    accept="image/gif, image/jpeg, image/png"
                                                    onChange={(e) => handlephoto(e.target.files[0])}
                                                    name="imageUpload"
                                                    id="imageUpload"
                                                    style={{ display: "none" }}
                                                />
                                                {dashBoardData && dashBoardData.user.image_url ? (
                                                    <label
                                                        className="light-green-text font-weight-100 font-size-11 cursor-pointer"
                                                        htmlFor="imageUpload"
                                                    >
                                                        Change Photo
                                                    </label>
                                                ) : (
                                                    <label
                                                        className="light-green-text font-weight-100 font-size-11 cursor-pointer"
                                                        htmlFor="imageUpload"
                                                    >
                                                        Add Photo
                                                    </label>
                                                )}
                                            </div>
                                        </span>
                                    </div>
                                    <div className="mb-2 d-flex justify-content-between">
                                        <span className="small-blurred-text font-size-12 mobile_fonts">
                                            Tell companies how you are a god fit
                                        </span>
                                        {/* <span className="f-g f-12">
                                            <div>
                                                <input type="file" accept="video/*" onChange={(e) => handleVideo(e.target.files[0])} name="videoUpload" id="videoUpload" style={{ display: "none" }} />
                                                <label className='light-green-text font-weight-100 font-size-11 cursor-pointer' htmlFor="videoUpload">ADD VIDEO</label>
                                            </div>
                                        </span> */}
                                        <span className=" f-g f-12">
                                            <label
                                                type="button"
                                                onClick={() => {
                                                    openCandidatureModal();
                                                }}
                                                className="light-green-text font-weight-100 font-size-11 cursor-pointer"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-lg"
                                            >
                                                {`${dashBoardData?.user?.video_url ? "Edit" : "Add"} Video`}
                                            </label>
                                            <CandidatureModal
                                                link={dashBoardData?.user?.video_url}
                                                modal_center={candidature_modal}
                                                centered={true}
                                                tog_center={() => openCandidatureModal()}
                                                onModalSubmit={updateCandidature}
                                            />
                                        </span>
                                    </div>
                                    <div className="mb-2 d-flex justify-content-between">
                                        <span className="small-blurred-text font-size-12 mobile_fonts">
                                            Become a verified user
                                        </span>
                                        <span className="f-g f-12">
                                            <div>
                                                <label
                                                    onClick={() => {
                                                        tog_large();
                                                    }}
                                                    className="light-green-text font-weight-100 font-size-11 cursor-pointer"
                                                >
                                                    Begin
                                                </label>
                                            </div>
                                            <AddBackgroundVerificationModal
                                                modal_center={modal_large}
                                                centered={true}
                                                tog_center={() => tog_large()}
                                                onModalSubmit={updateBgModalData}
                                            />
                                        </span>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="overflow-hidden">
                                <div className="font-size-16 text-muted font-weight-100 pl-4 pt-4 pr-4 mb-2">
                                    Knowledge References
                                </div>
                                <CardBody className="text-left pt-0 pl-4 pr-4">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="d-flex justify-content-between flex-column position-relative">
                                                <div>
                                                    <img className="udemy_icon" src={udemy_icon} alt="udemy" />
                                                </div>
                                                <div className="mt-2 font-size-14">UDEMY</div>
                                                <div className="font-weight-100 font-size-11 cursor-pointer site_icon_wrapper">
                                                    <img src={site_icon} alt="site_icon" />
                                                    <a
                                                        className="light-green-text ml-1"
                                                        href="https://www.udemy.com/"
                                                        target="_blank"
                                                        htmlFor="test"
                                                    >
                                                        Visit Site
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="mt-4 d-flex justify-content-between flex-column position-relative">
                                                <div>
                                                    <img
                                                        className="cousera_icon"
                                                        src={coursera_icon}
                                                        alt="cousera_icon"
                                                    />
                                                </div>
                                                <div className="mt-2 font-size-14">COURSERA</div>
                                                <div className="light-green-text font-weight-100 font-size-11 cursor-pointer site_icon_wrapper align-items-center">
                                                    <img src={site_icon} alt="site_icon" />
                                                    <a
                                                        className="light-green-text ml-1"
                                                        href="https://www.coursera.org/"
                                                        target="_blank"
                                                        htmlFor="test"
                                                    >
                                                        Visit Site
                                                    </a>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8">
                            <div>Activity</div>
                            {activityList &&
                                activityList.length > 0 &&
                                activityList.map((item, index) => {
                                    return (
                                        <div key={index} className="activity-container mt-2">
                                            <Card className="mb-1">
                                                <CardHeader
                                                    className={`${!col1 ? "collapse-shadow" : ""
                                                        } p-2 pl-3 pr-3 d-none d-sm-block d-md-block d-lg-block d-xl-block`}
                                                    id="headingOne"
                                                    onClick={() => {
                                                        props.history.push(`/studentJobDetail/${item.job.id}`);
                                                    }}
                                                >
                                                    <div className="web_display_flex" style={{justifyContent:"flex-start"}}>
                                                        <div className="d-flex  align-items-center">
                                                            <div className="mr-2 font-size-14" style={{width:"300px"}}> 
                                                                {" "}
                                                                <img src={company_img} alt="company" />{" "}
                                                                {item.job.company}{" "}
                                                            </div>
                                                            <span className="vertial_divider"> | </span>
                                                            <div style={{width:"300px"}}>
                                                                {" "}
                                                                <span className="font-size-16 font-weight-medium green-text-color">
                                                                    Designation:
                                                                </span>{" "}
                                                                <span className="ml-1">{item.job.title} </span>{" "}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                            {item.applicationsStatus !== "Not applied" ? (
                                                                item.applicationsStatus === "hold" ? (
                                                                    <span className="orange-status-text mr-2">
                                                                        On hold
                                                                    </span>
                                                                ) : item.applicationsStatus === "select" ? (
                                                                    <span className="green-status-text mr-2">
                                                                        Accepted
                                                                    </span>
                                                                ) : item.applicationsStatus === "reject" ? (
                                                                    <span className="red-status-text mr-2">
                                                                        Rejected
                                                                    </span>
                                                                ) : (
                                                                    <span className="blue-status-text mr-2">
                                                                        Shortlisted
                                                                    </span>
                                                                )
                                                            ) : (
                                                                <span className="blue-status-text mr-2">
                                                                    Shortlisted
                                                                </span>
                                                            )}

                                                            <span className="mr-1 font-size-11 text-muted font-weight-100">
                                                                {timeSince(new Date(item.job.createdAt))} ago
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardHeader>

                                                <CardHeader
                                                    className={`${!col1 ? "collapse-shadow" : ""
                                                        } p-2 pl-3 pr-3 d-block d-sm-none`}
                                                    id="headingOne"
                                                    onClick={() => {
                                                        props.history.push(`/studentJobDetail/${item.job.id}`);
                                                    }}
                                                >
                                                    <div className="web_display_flex">
                                                        <div className="">
                                                            <div className="mr-2 font-size-14">
                                                                {" "}
                                                                <img src={company_img} alt="company" />{" "}
                                                                {item.job.company}{" "}
                                                            </div>
                                                            <div className="mt-2">
                                                                {" "}
                                                                <span className="font-size-16 font-weight-medium green-text-color">
                                                                    Designation:
                                                                </span>{" "}
                                                                <span className="ml-1">{item.job.title} </span>{" "}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex  align-items-center">
                                                            {item.applicationsStatus !== "Not applied" ? (
                                                                item.applicationsStatus === "hold" ? (
                                                                    <span className="orange-status-text mr-2">
                                                                        On hold
                                                                    </span>
                                                                ) : item.applicationsStatus === "select" ? (
                                                                    <span className="green-status-text mr-2">
                                                                        Accepted
                                                                    </span>
                                                                ) : item.applicationsStatus === "reject" ? (
                                                                    <span className="red-status-text mr-2">
                                                                        Rejected
                                                                    </span>
                                                                ) : (
                                                                    <span className="blue-status-text mr-2">
                                                                        Shortlisted
                                                                    </span>
                                                                )
                                                            ) : (
                                                                <span className="blue-status-text mr-2">
                                                                    Shortlisted
                                                                </span>
                                                            )}

                                                            <span className="mr-1 font-size-11 text-muted font-weight-100">
                                                                {timeSince(new Date(item.job.createdAt))} ago
                                                            </span>
                                                            <span
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleRow(index);
                                                                }}
                                                                className={`font-size-18 cursor-pointer`}
                                                            >
                                                                {item.open ? (
                                                                    <i className="bx bxs-chevron-up text-muted mt-2"></i>
                                                                ) : (
                                                                    <i className="mt-2 text-muted bx bxs-chevron-down"></i>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardHeader>


                                            </Card>
                                        </div>
                                    );
                                })}
                        </Col>
                    </Row>
                    <BackgroundSuccessModal
                        modal_center={bg_succes}
                        centered={true}
                        tog_center={() => tog_bg()}
                        bgSuccessSumbit
                        onModalSubmit={bgSuccessSumbit}
                    />
                </Container>
            </div>
            {/* <div style={{ minHeight: "30vh" }} > */}

            {/* </div> */}
        </React.Fragment>
    );
};

export default withRouter(StudentDashboard);
