import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DateRangePicker from "../../../components/DateRangePicker";
import Common from "../../../helpers/common";

const EmploymentTypes = [
  {
    value: 1,
    label: "Full-Time Employee",
  },
  {
    value: 2,
    label: "Part-Time Employee",
  },
  {
    value: 3,
    label: "Temporary Employee",
  },
  {
    value: 4,
    label: "Seasonal Employee",
  },
  {
    value: 5,
    label: "Internship/Project",
  },
];

const AddEmpModal = (props) => {
  const [jobrole, setJobRole] = useState();
  const [company, setCompany] = useState();
  const [empType, setEmpType] = useState();
  const [location, setLocation] = useState();
  const [sDate, setSDate] = useState();
  const [eDate, setEDate] = useState();
  const [stillWorking, setStillWorking] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const [description, setDescription] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.empEditedData) {
      setJobRole(props.empEditedData.role);
      setCompany(props.empEditedData.company);
      setLocation(props.empEditedData.city);
      setSDate(
        props.empEditedData.start_year
          ? new Date(props.empEditedData.start_year)
          : ""
      );
      setEDate(
        props.empEditedData.grad_year
          ? new Date(props.empEditedData.grad_year)
          : ""
      );
      setStillWorking(props.empEditedData.still_working);
      setDescription(props.empEditedData.description);
      setEmpType({
        ...empType,
        label: props.empEditedData.emp_type,
      });
      setDateDisabled(props.empEditedData.still_working);
    } else {
      clearData();
    }
  }, [props.empEditedData]);

  function clearData() {
    setJobRole();
    setCompany();
    setLocation();
    setSDate();
    setEDate();
    setDescription();
    setStillWorking(false);
    setEmpType({});
  }

  const handleChange = (selectedOption) => {
    setEmpType(selectedOption);
  };

  function handleEditButton() {
    if (
      !jobrole ||
      !company ||
      !empType ||
      !location ||
      !description ||
      !sDate ||
      !(eDate || stillWorking)
    ) {
      setShowError(true);
      return;
    }

    props.onModalSubmit(
      {
        jobrole,
        company,
        location,
        sDate,
        eDate,
        stillWorking,
        duration: "5 months",
        id: props.empEditedData.id,
        empType: empType.label,
        description: description,
      },
      "edit"
    );
  }

  function handleSaveButton() {
    if (
      !jobrole ||
      !company ||
      !empType ||
      !location ||
      !description ||
      !sDate ||
      !(eDate || stillWorking)
    ) {
      setShowError(true);
      return;
    }
    props.onModalSubmit(
      {
        jobrole,
        company,
        location,
        sDate,
        eDate,
        stillWorking,
        duration: "5 months",
        empType: empType.label,
        description: description,
      },
      "save"
    );
    clearData();
  }

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="emp-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          {props.empEditedData ? "Edit" : "Add"} Employment Details
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>
                Current/Latest Job Role
                <span className="label-required"> * </span>
              </Label>
              <Input
                maxLength="40"
                type="text"
                value={jobrole || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(
                    e.target.value.replace(/[^a-zA-Z\s]/gi, "")
                  );
                  if (e.target.value.trim() === "") {
                    setShowError("Should not be blank");
                  } else {
                    setShowError("");
                  }
                  setJobRole(e.target.value);
                }}
              />
              {(showError || jobrole !== undefined) && !jobrole && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Job Role field cannot be blank.
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>
                Company<span className="label-required"> * </span>
              </Label>
              <Input
                maxLength="40"
                type="text"
                name="select"
                value={company || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(
                    e.target.value.replace(/[^a-zA-Z\s]/gi, "")
                  );
                  setCompany(e.target.value);
                }}
              />
              {(showError || company !== undefined) && !company && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Company name cannot be blank.
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-colg-input1">
                Employment Type<span className="label-required"> * </span>
              </Label>
              <Select
                className="select-container"
                value={empType}
                onChange={handleChange}
                options={EmploymentTypes}
                isSearchable
                innerProps={{ className: "test" }}
                placeholder="Select"
              />
              {(showError || empType !== undefined) && !empType && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Choose an Employment type from the dropdown above.
                </label>
              )}

              {/* <Input type="select" name="select" value={empType} onChange={(e) => setEmpType(e.target.value)}>
                                <option></option>
                                <option>Part Time</option>
                                <option>Full Time</option>
                            </Input>       */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="mb-0 text-left">
              <Label>
                Start Year<span className="label-required"> * </span>
              </Label>
              <InputGroup>
                <DateRangePicker
                  proptype="start"
                  propsref="startcalendar"
                  propsid="startyear"
                  selected={sDate}
                  onChange={(date) => setSDate(date)}
                  selectsStart
                  startDate={sDate}
                  endDate={sDate}
                  maxDate={eDate}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showDisabledMonthNavigation
                />
                <label htmlFor="startyear">
                  {" "}
                  <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>{" "}
                </label>
              </InputGroup>
              {(showError || sDate !== undefined) && !sDate && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Enter the starting year of the job.
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-0 text-left">
              <Label>
                End Year<span className="label-required"> * </span>
              </Label>
              <InputGroup>
                <DateRangePicker
                  proptype="end"
                  propsref="endcalendar"
                  propsid="endyear"
                  className="form-control"
                  selected={eDate}
                  onChange={(date) => setEDate(date)}
                  selectsEnd
                  startDate={sDate}
                  endDate={eDate}
                  minDate={sDate}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showDisabledMonthNavigation
                  readOnly={dateDisabled}
                />
                <label htmlFor="endyear">
                  {" "}
                  <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>{" "}
                </label>
              </InputGroup>
              {(showError || eDate !== undefined) &&
                !eDate &&
                !dateDisabled && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    Enter the ending year of the job.
                  </label>
                )}

              <FormGroup className="pt-2" check>
                <Label className="main" check>
                  <Input
                    type="checkbox"
                    check={
                      stillWorking ? stillWorking.toString() : false.toString()
                    }
                    onChange={(event) => {
                      setStillWorking(event.target.checked);
                      if (event.target.checked) {
                        setEDate("");
                        setDateDisabled(true);
                      } else {
                        setDateDisabled(false);
                      }
                    }}
                  />{" "}
                  I'm still working
                  <span className="geekmark"></span>
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Location</Label>
              <Input
                maxLength="40"
                type="text"
                value={location || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(
                    e.target.value.replace(/[^a-zA-Z\s]/gi, "")
                  );
                  setLocation(e.target.value);
                }}
              />
              {showError && location !== undefined && !location && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Enter your location.
                  <span className="label-required"> * </span>
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Description</Label>
              <Input
                maxLength="150"
                type="text"
                name="select"
                value={description || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(
                    e.target.value.replace(/[^a-zA-Z\s]/gi, "")
                  );
                  setDescription(e.target.value);
                }}
              />
              {showError && !description && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Enter your job desription.
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn dark-green-bord px-4 waves-effect"
          data-dismiss="modal"
        >
          Skip
        </button>
        {props.empEditedData ? (
          <button
            type="button"
            className="btn bg-green waves-effect px-4 waves-light"
            onClick={() => handleEditButton()}
          >
            Save{" "}
          </button>
        ) : (
          <button
            type="button"
            className="btn bg-green waves-effect waves-light px-4"
            onClick={() => handleSaveButton()}
          >
            Save{" "}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default AddEmpModal;
