import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col } from "reactstrap";
import AddSkillsModal from './AddSkillsModal';
import ManageSkillsModal from './ManageSkillsModal';
import classnames from 'classnames';
import professional_icon from '../../../assets/images/professional_icon.png';
import beginner_icon from '../../../assets/images/begineer_icon.png';
import intermidiate_icon from '../../../assets/images/intermidiate_icon.png';

import ApiService from '../../../config/restapi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { getAllUserData } from '../../../redux/actions/profile';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

const Skills = (props) => {

    const [skillsLearned, setSkillsLearned] = useState([{ name: "Design", proficiency: 'beginner' }, { name: "Design Architecture", proficiency: 'intermidiate' }, { name: "Design Architecture", proficiency: 'professional' }])
    const [makeDots, setMakeDots] = useState([{ name: 'first' }, { name: 'second' }, { name: 'third' }, { name: 'fourth' }, { name: 'fifth' }]);
    const [modal_center, setmodal_center] = useState(false);
    const [modal_manage_skill, setmodal_manage_skill] = useState(false);
    const [editedData, setEditedData] = useState();

    const dotHandler = (index) => {
        const tempData = [...makeDots];
        if (index > -1) {
            tempData[index]["selected"] = !makeDots[index].selected;
        }
        setMakeDots(tempData);
    }


    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const updateSkills = (data) => {
        console.log(props.user)
        //to do call api and pass data
        if (props.user && props.user.skills) {
            let skilList = [...props.user.skills, ...data.skills];
            ApiService.editProfileData({ skills: skilList }).then((res) => {
                if (res && res.status === 200 && res.data) {
                    props.getAllUserData();
                    if (!props.error) {
                        tog_center(false);
                        toastr.success("Skill Added Successfully!", "", { timeOut: 2000 });
                    }
                }
            });
        }
    }

    const manageSkills = (data) => {
        console.log(data);
        ApiService.editProfileData(data).then((res) => {
            if (res && res.status === 200 && res.data) {
                props.getAllUserData();
                if (!props.error) {
                    setmodal_manage_skill(!modal_manage_skill);
                    toastr.success("Skill Updated Successfully!", "", { timeOut: 2000 });
                }
            }
        });
    }

    function openManageSkillModal() {
        setEditedData(props.user);
        setmodal_manage_skill(!modal_manage_skill);
        removeBodyCss();
    }


    return (
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="5" className="skill-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                Skills
                            </h6>
                            <span>
                                {props.user && props.user.skills && props.user.skills.length > 0 && <i className="bx bx-pencil font-size-16 cursor-pointer pr-3" onClick={() => { openManageSkillModal() }}></i>}
                                <span className="font-size-18 pr-3 cursor-pointer" onClick={() => { tog_center() }}>
                                    +
                                </span>
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col sm="12">
                        {props.user && props.user.skills && props.user.skills.length > 0 && <ul className="skills-learns pl-0">
                            {props.user.skills.map((item, idx) => {
                                let item1 = JSON.parse(item);
                                return (
                                    <li className={classnames("d-inline-block mr-2 skills-items skill-container", { 'mb-3': (skillsLearned.length - 1) !== idx })} key={idx}>
                                        <span className="mr-2"> {item1.label} </span>
                                        {item1.proficiency === 'beginner' && <img src={beginner_icon} alt='skills' />}
                                        {item1.proficiency === 'intermidiate' && <img src={intermidiate_icon} alt='skills' />}
                                        {item1.proficiency === 'professional' && <img src={professional_icon} alt='skills' />}
                                    </li>
                                )
                            })}
                        </ul>}
                    </Col>
                </Row>
            </TabPane>
            <AddSkillsModal
                modal_center={modal_center}
                centered={true}
                tog_center={() => tog_center()}
                onModalSubmit={updateSkills}
            />
            <ManageSkillsModal
                modal_center={modal_manage_skill}
                centered={true}
                tog_center={() => openManageSkillModal()}
                onModalSubmit={(d) => manageSkills(d)}
                editedData={editedData}
            />
        </TabContent>
    )
}

const mapStateToProps = (state) => {
    return {
        user: {...state.profile.user
    }
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getAllUserData: getAllUserData,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Skills);