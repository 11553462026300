import React, { useEffect, useState  } from 'react';
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from 'classnames';
import CompanyDetails from "./CollegeDetails";
import AboutCompany from './DegreeCourse';
import ContactInfo from "./ContactInfo";
import { withRouter } from 'react-router-dom';
import ApiService from '../../../config/restapi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const CompanyWizard = (props) => {

    const [companyDetailObj, setCompanyDetailObj] = useState({});

    const wizardHeadersNames = [{ name: "College Details", key: "pd-width" },
    { name: "Degrees and Courses", key: "ed-width" },
    { name: "Contact Information", key: "empd-width" }];

    const [wizardHeaders, setwizardHeaders] = useState([]);
    const [activeTab, setactiveTab] = useState(1);


    const OnchangeCallBack = (value, type) => {
        switch (type) {
            case 'designation':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    designationObj:value
                })
                break;    
            case 'name':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    name:value
                })
                break;
            case 'websiteurl':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    website_url:value
                })
                break;
            case 'company_logo':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    logo_url:value
                })
                break;
            case 'degree':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    size:value.label,
                    selectedOpt:value
                })
                break;
            case 'industry':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    industry:value.label,
                    industryObj:value
                })
                break;
            case 'description':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    description:value
                })
                break;
            case 'location':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    location:value
                })
                break;   
            case 'email':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    email:value
                })
                break;   
            case 'mobile':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    number:value
                })
                break; 
            case 'isd_code':
                setCompanyDetailObj({
                    ...companyDetailObj,
                    isd_code: value
                })
                break;    
            default:
                break;
        }
    }

    
    const onNextClickEduDataCallBack = (eduData,rowData,editIndex)=>{
       
    }

    const onNextClickEmploymentCallBack = (empData,rowData,editIndex)=>{
       
    }


    const onSkipClick = (tab) => {
        if (tab === 1) {
            setactiveTab(2);
        } else if (tab === 2) {
            setactiveTab(3);
        } else if (tab === 3) {
            props.onCompleteAboutYourselfCallBack();
        }
    }

    const onNextClick = (tab) => {
        if (tab === 1) {
            setactiveTab(2);
        } else if (tab === 2) {
            setactiveTab(3);
        } else if (tab === 3) {
            let obj = {
                ...companyDetailObj,
                URL : companyDetailObj.website_url
            }
            props.history.push('/collegeDashboard');
            // ApiService.editProfileData(obj).then((res) => {
            //     if(res && res.status === 200 && res.data.success){
            //         props.history.push('/jobdashboard');
            //     }else{
            //         toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            //     }
            // });
        }
    }

    useEffect(() => {        
        ApiService.getProfileDetails().then((res) => {
            if(res && res.status === 200 && res.data){
                setCompanyDetailObj({
                    ...companyDetailObj,
                    name : res.data.name
                });
            }else{
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
        });
       
    }, []);
    
   
    useEffect(() => {
        wizardHeadersNames.map((obj, index) => {
            if (activeTab === (index + 1)) {
                obj["active"] = "active";
                obj["key"] = wizardHeadersNames[index].key;
            } else {
                delete obj.active;
                delete obj.key;
            }
            if (activeTab === 2 || activeTab === 3) {
                if (activeTab === 3) wizardHeadersNames[activeTab - 3]["makeactive"] =  (activeTab - 3);
                wizardHeadersNames[activeTab - 2]["makeactive"] = (activeTab - 2);
                wizardHeadersNames[activeTab - 2]["lessWidthD"] = "less-width-d";
            }
        })
        setwizardHeaders([...wizardHeadersNames]);
    }, [activeTab]);

    const ToggleHeaderTab = (tab,item)=>{
        if("makeactive" in item){
            setactiveTab(item.makeactive + 1);
        }
    }

    const WizardHeaders = (item1, idx) => {
        return (
            <NavItem key={idx}>
                <NavLink className={`text-center ${item1.active ? item1.active : ''}`}>
                    <span onClick={()=>{ToggleHeaderTab(activeTab,item1)}} className={classnames('step-number mr-1', { [`${item1.key}`]: item1.key },
                        { [`${item1.lessWidthD}`]: item1.lessWidthD },
                        { [`${item1.solidImage}`]: item1.solidImage })}>{idx + 1}</span>
                    {item1.name ? <div className={classnames('font-size-12 mt-2')} > {item1.name} </div> : ''}
                </NavLink>
            </NavItem>
        )
    }

    return (
        <div id="basic-pills-wizard" className="twitter-bs-wizard company-wizard">
            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
                {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
            </ul>
            <Card>
                <CardBody>
                    {activeTab === 1 ? <CompanyDetails
                            activeTab={activeTab}
                            OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)} 
                            details={companyDetailObj}
                        /> :
                        (activeTab === 2 ? <AboutCompany
                            activeTab={activeTab}
                            OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)} 
                            onNextClickEduDataCallBack={onNextClickEduDataCallBack}
                            details={companyDetailObj}
                        /> :
                            <ContactInfo
                                activeTab={activeTab}
                                OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)} 
                                details={companyDetailObj}
                                onNextClickEmploymentCallBack={onNextClickEmploymentCallBack}
                            />)
                    }
                </CardBody>
            </Card>
            <div className='text-right'>
                {activeTab !== 3 && <button
                    className={classnames('btn btn-outline-grey waves-effect waves-light w-lg mr-2')}
                    type="submit"
                    onClick={() => onSkipClick(activeTab)}
                >Skip
                </button>}
                <button className="btn bg-green waves-effect waves-light w-lg"
                    type="submit"
                    onClick={() => onNextClick(activeTab)}
                >{activeTab === 3 ? "Start Exploring" : "Next"}</button>
            </div>
        </div>
    )
}

export default withRouter(CompanyWizard);