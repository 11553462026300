import React, { useState, useEffect } from 'react';
import { TabContent,TabPane, Form, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import TextArea from '../../../components/TextArea';
import Select from 'react-select';
import {Domains,CollegeDegrees,CollegeCourses} from '../../../helpers/constant_variables';

const AboutCompany = (props) =>{

    const [descriptioncharcomplete, setDescriptioncharcomplete] = useState(0);

    
    function onChangeTextareaHandler(event){
        // const charCount = event.target.value.length;
        var regex = /\s+/gi;
        var WordCount = event.target.value === "" ? 0 : event.target.value.trim().replace(regex, ' ').split(' ').length;        
        if(WordCount <= 500) setDescriptioncharcomplete(WordCount);
        props.OnchangeCallBack(event.target.value, 'description');
    }

    return(
        <TabContent activeTab={props.activeTab} className="twitter-bs-wizard-tab-content pt-0">
            <TabPane tabId={2} className="about-company text-left">
                <Form>
                    <Row>
                        <Col lg="12">
                            <FormGroup className="text-left">
                                <Label for="basicpill-firstname-input1">Degree</Label>
                                <Select
                                    className="select-container"
                                    value={props.details.selectedOpt}
                                    onChange={(selectedOption)=>props.OnchangeCallBack(selectedOption, 'degree')}
                                    options={CollegeDegrees}
                                    isSearchable 
                                    innerProps={{className:"test"}}
                                    placeholder="Select"
                                />  
                                {/* <Input type="text" value={props.details.size || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'size')} /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FormGroup className="text-left">
                                <Label for="basicpill-firstname-input1">Courses</Label>
                                {/* <Input type="text" value={props.details.industry || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'industry')} /> */}
                                <Select
                                    className="select-container"
                                    value={props.details.industryObj}
                                    onChange={(selectedOption) => props.OnchangeCallBack(selectedOption, 'industry')}
                                    options={Domains}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-firstname-input1">Total students registered for the degree </Label>
                                <Input type="tel" value={props.details.name || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'name')} />
                            </FormGroup>
                        </Col>
                    </Row>     
                </Form>
            </TabPane>
        </TabContent>
    )
}

export default AboutCompany;