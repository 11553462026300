import React, { useState, useEffect } from "react";
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import cloudOutline from "../../../assets/images/cloud-outline.svg";
import pdf_icon from "../../../assets/images/pdf_icon.png";

import ApiService from "../../../config/restapi";
import Common from "../../../helpers/common";
const AddCourseCompletedModal = (props) => {
  const [courseName, setCourseName] = useState();
  const [skills, setSkills] = useState();
  const [uplodedFiles, setUplodedFiles] = useState(undefined);
  const [invalidFormat, setInvalidFormat] = useState("");

  useEffect(() => {
    if (props.editedData) {
      console.log("edit");
      setCourseName(props.editedData.title);
      setSkills(props.editedData.topic);
      setUplodedFiles(props.editedData.link);
    } else {
      clearData();
    }
  }, [props.editedData]);

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (
      ext === "pdf" ||
      ext === "docx" ||
      ext === "doc" ||
      ext === "png" ||
      ext === "jpg" ||
      ext == "jpeg"
    ) {
      return true;
    }
    return false;
  }

  function handleAcceptedFiles(files) {
    if (validateFileExtention(files)) {
      setUplodedFiles(files);
    }
  }

  function clearData() {
    setCourseName();
    setSkills();
    setUplodedFiles();
  }

  function onSubmit(type) {
    if (courseName) {
      var RegEx = /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/;
      var Valid = RegEx.test(courseName);
      if (!Valid) {
        setInvalidFormat("Numbers are not allowed");
        return;
      }
    }

    if (type == "save") {
      props.onModalSubmit(
        { title: courseName, topic: skills, cert: uplodedFiles },
        "save"
      );
      clearData();
    } else if (type == "edit") {
      if (typeof uplodedFiles === "string") {
        props.onModalSubmit(
          {
            title: courseName,
            topic: skills,
            id: props.editedData.id,
            link: uplodedFiles,
          },
          "edit"
        );
        clearData();
      } else {
        props.onModalSubmit(
          {
            title: courseName,
            topic: skills,
            cert: uplodedFiles,
            id: props.editedData.id,
          },
          "edit"
        );
        clearData();
      }
    }
  }

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="add-course-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          {props.editedData ? "Edit" : "Add"} Completed Course
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>
                Course Name<span className="label-required"> * </span>
              </Label>
              <Input
                type="text"
                maxLength="40"
                value={courseName || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(
                    e.target.value.replace(/[^\w\s]/gi, "")
                  );
                  setCourseName(e.target.value);
                  if (invalidFormat) {
                    setInvalidFormat("");
                  }
                }}
              />
              {!courseName && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Course name should not be blank.
                </label>
              )}{" "}
              {invalidFormat && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {invalidFormat ? invalidFormat : ""}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>
                Applicable Skills<span className="label-required"> * </span>
              </Label>
              <Input
                type="select"
                name="select"
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
              >
                <option value={undefined}>Select</option>
                <option value="Fullstack Development">
                  Fullstack Development
                </option>
                <option value="Frontend Development">
                  Frontend Development
                </option>
                <option value="Dev Ops">Dev Ops</option>
              </Input>
              {skills === "Select" && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Select an Applicable skill from the dropdown..
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <FormGroup className="mb-0 text-left">
              <Label>Upload Course Completion Certificate</Label>
              {!uplodedFiles && (
                <div className="">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <button
                              type="button"
                              className="btn btn-outline-shadow waves-effect waves-light font-size-16"
                            >
                              {" "}
                              <img src={cloudOutline} alt="cloud" />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              )}
              <div className="pt-2 apply_resume_wrapper">
                {uplodedFiles && (
                  <div className="company-logo-img">
                    <img src={pdf_icon} alt="" className="courses-cert-img" />
                    <span
                      className="img-close"
                      onClick={() => setUplodedFiles(undefined)}
                    >
                      &times;
                    </span>
                  </div>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        {props.editedData ? (
          <button
            disabled={!courseName || !skills || !uplodedFiles}
            type="button"
            className="btn bg-green waves-effect waves-light"
            onClick={() => {
              onSubmit("edit");
            }}
          >
            Save{" "}
          </button>
        ) : (
          <button
            disabled={!courseName || !skills || !uplodedFiles}
            type="button"
            className="btn bg-green waves-effect waves-light"
            onClick={() => {
              onSubmit("save");
            }}
          >
            Save{" "}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default AddCourseCompletedModal;
