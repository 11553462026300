import React from 'react';

import { Form, Row, Col, Modal } from "reactstrap";

// Redux
import { withRouter, Link } from 'react-router-dom';

const BackgroundSuccessModal = (props) => {

    return (
        <Modal
            size="lg"
            isOpen={props.modal_center}
            toggle={() => {
                props.tog_center();
            }}
            className="bg-verification-modal"
        >
            <Form>
                <div className="modal-header">
                    <button
                        onClick={() => {
                            props.tog_center(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="container">
                        <Row>
                            <Col className="col-12">
                                <div className="pl-4 pb-2">
                                    <h5 className="font-size-18 font-weight-bold"> Check Your Inbox </h5>
                                </div>
                                <div className="pl-4 text-left signup-description">
                                    <div>You have successfully submitted your documents for verification. </div>
                                    <div>You’ll get a notification within 24-36 hours once the process is complete</div>
                                </div>
                                <div className="pl-4">
                                    <div className="mt-3 border-bottom pb-3 text-left">
                                        <button
                                            onClick={() => {
                                                props.tog_center(false)
                                            }}
                                            className="btn bg-green waves-effect waves-light w-md" type="submit">Continue</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </Modal>
    );
}

export default withRouter(BackgroundSuccessModal);
