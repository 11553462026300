import React from 'react';
import GoogleLogin from 'react-google-login';
import googleWhite from '../../assets/images/google_white_bg.png';


const GoogleLoginButton = ({ onSuccess,onFailure}) => {
    return(
        <GoogleLogin
            clientId="704467432405-9ntdnbep348adavm2bojj1ecq2fa00db.apps.googleusercontent.com"
            render={renderProps => (
                <img className="mr-2 cursor-pointer" src={googleWhite} alt='google' onClick={renderProps.onClick} disabled={renderProps.disabled}/>
            )}
            buttonText="Login"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
        />
    );
}
export { GoogleLoginButton };