import React, { useEffect, useState } from "react";
import {
  Media,
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";
import post_job_icon from "../../../assets/images/post_job_icon.png";
import classnames from "classnames";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import InviteUserModal from "./InviteUserModal";
import import_icon from "../../../assets/images/importicon.svg";
// Redux
//import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

const dummyData = [
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
];

const CollegeDashboard = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [modal_center, setmodal_center] = useState(false);
  const [jobList, setJobList] = useState(dummyData);

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateInviteUser = () => {
    // console.log('on modal close')
  };

  function changeMenuState(index) {
    let tempData = [...jobList];
    tempData[index].menu = !tempData[index]["menu"];
    setJobList([...tempData]);
  }

  function openDropDownRow(index) {
    let tempData = [...jobList];
    tempData[index]["isexpand"] = !tempData[index]["isexpand"];
    setJobList([...tempData]);
  }

  return (
    <React.Fragment>
      <div className="page-content job-dashbord">
        <Row>
          <Col
            xl="6"
            className="pl-4 pr-4 text-left green-text-color font-weight-medium"
          >
            Students
          </Col>
          <Col xl="6" className="pl-4 pr-4 text-right">
            <button
              className="btn btn-outline-shadow waves-effect waves-light w-md mr-2"
              type="button"
              onClick={() => props.history.push("/jobwizard")}
            >
              <img className="mr-2" src={import_icon} alt="job-post" /> IMPORT
              CSV
            </button>
            <button
              className="btn btn-outline-shadow waves-effect waves-light w-md"
              type="button"
              onClick={() => tog_center()}
            >
              <img className="mr-2" src={post_job_icon} alt="job-post" /> INVITE
              STUDENTS
            </button>
          </Col>
          <InviteUserModal
            modal_center={modal_center}
            centered={true}
            tog_center={() => tog_center()}
            onModalSubmit={updateInviteUser}
          />
        </Row>
        <Row className="mt-2">
          <Col xl="12" className="pl-4 pr-4">
            <div className="job-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Active (50)
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Invited (40)
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xl="2" className="pl-4 pr-4 text-left">
            <div className="form-group m-0 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Job Title"
                  aria-label="Recipient's username"
                />
                <div className="input-group-append">
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {activeTab === "1" && (
          <div>
            <Row className="mt-2">
              <Col xl="12" className="pl-4 pr-4 text-left">
                <Row className="row-header font-weight-medium font-size-16">
                  <div className="name-w pl-2 pr-2">Name</div>
                  <div className="email-w pl-2 pr-2">Email Address</div>
                  <Col xl="2" className="">
                    Degree and Course
                  </Col>
                  <Col xl="2" className="">
                    # of active interviews
                  </Col>
                  <Col xl="2" className="">
                    # of accepted offers
                  </Col>
                  <div className="total-interview-w">Total Interviews</div>
                  <div className="job-w">Added On</div>
                  <Col>
                    <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                  </Col>
                </Row>
              </Col>
            </Row>
            {jobList.map((item, index) => {
              return (
                <Row key={index} className="mt-2 text-left">
                  <Col xl="12" className="pl-4 pr-4 text-left">
                    <Row
                      className="row-items green-text-color cursor-pointer"
                      onClick={() => {}}
                    >
                      <div className="name-w pl-2 pr-2">
                        <div className="font-size-16">{item.name}</div>
                        <div className="font-size-12"></div>
                      </div>
                      <div className="email-w pl-2 pr-2">{item.email}</div>
                      <Col xl="2" className="text-truncate">
                        <div className="font-size-16">{item.degree}</div>
                        <div className="font-size-12">{item.course}</div>
                      </Col>
                      <Col xl="2" className="">
                        <div className="font-size-16 d-flex">
                          {item.active_intervews}
                        </div>
                      </Col>
                      <Col xl="2" className="">
                        <div className="font-size-16">
                          {item.accepted_offer}
                        </div>
                      </Col>
                      <div className="total-interview-w">
                        <div className="font-size-16 d-flex align-items-center">
                          {item.total_interviews}
                          <span
                            onClick={() => {
                              openDropDownRow(index);
                            }}
                            className={`font-size-20 ml-2 cursor-pointer`}
                          >
                            {item.isexpand ? (
                              <i className="bx bxs-chevron-up text-muted mt-2"></i>
                            ) : (
                              <i className="mt-2 text-muted bx bxs-chevron-down"></i>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="job-w">
                        <div className="font-size-16">{item.addedOn}</div>
                      </div>
                      <Col>
                        <Dropdown
                          isOpen={item.menu}
                          toggle={(event) => {
                            event.stopPropagation();
                            changeMenuState(index);
                          }}
                          className="d-inline-block"
                        >
                          <DropdownToggle
                            className="btn header-item waves-effect"
                            id="page-header-user-dropdown"
                            tag="button"
                          >
                            <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer mr-2"></i>
                          </DropdownToggle>
                          <DropdownMenu className="green-text-color" right>
                            <DropdownItem
                              className="green-text-color"
                              tag="a"
                              href="#"
                              onClick={() => {}}
                            >
                              View Profile
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="12">
                    <Collapse
                      isOpen={item.isexpand}
                      className={`pl-4 pr-4 accordin-open-container`}
                    >
                      <Row>
                        <Col xl="12" className="row-expand">
                          <ul className="verti-timeline list-unstyled pl-0">
                            <li className="event-list d-flex">
                              <div className="event-timeline-dot list-dot">
                                {" "}
                              </div>
                              <Media className="w-100">
                                <Media
                                  className="d-flex justify-content-between"
                                  body
                                >
                                  <div>
                                    <i className="far fa-building mr-1"></i>
                                    <span className="ml1">Fountane. LLC</span>
                                    <span className="ml-2">
                                      shortlisted the candidate for the role of
                                      a UX Designer
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <span className="blue-status-text mr-2">
                                        Shortlisted
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-muted font-size-11">
                                        2 days ago
                                      </span>
                                    </div>
                                  </div>
                                </Media>
                              </Media>
                            </li>
                            <li className="event-list d-flex">
                              <div className="event-timeline-dot list-dot">
                                {" "}
                              </div>
                              <Media className="w-100">
                                <Media
                                  className="d-flex justify-content-between"
                                  body
                                >
                                  <div>
                                    <i className="far fa-building mr-1"></i>
                                    <span className="ml1">Google</span>
                                    <span className="ml-2">
                                      has put the candidate on hold for the role
                                      of a UX Designer
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <span className="orange-status-text mr-2">
                                        On hold
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-muted font-size-11">
                                        2 days ago
                                      </span>
                                    </div>
                                  </div>
                                </Media>
                              </Media>
                            </li>
                            <li className="event-list d-flex">
                              <div className="event-timeline-dot list-dot">
                                {" "}
                              </div>
                              <Media className="w-100">
                                <Media
                                  className="d-flex justify-content-between"
                                  body
                                >
                                  <div>
                                    <i className="far fa-building mr-1"></i>
                                    <span className="ml1">Infosys</span>
                                    <span className="ml-2">
                                      accepted the candidate for the role of a
                                      UX Designer
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <span className="green-status-text mr-2">
                                        Accepted
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-muted font-size-11">
                                        2 days ago
                                      </span>
                                    </div>
                                  </div>
                                </Media>
                              </Media>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Collapse>
                  </Col>
                </Row>
              );
            })}
          </div>
        )}

        {activeTab === "2" && (
          <div>
            <Row className="mt-2">
              <Col xl="12" className="pl-4 pr-4 text-left">
                <Row className="row-header font-weight-medium font-size-16">
                  <div className="name-w pl-2 pr-2">Name</div>
                  <div className="email-w pl-2 pr-2">Email Address</div>
                  <Col xl="2" className="">
                    Degree and Course
                  </Col>
                  <Col xl="2" className="">
                    # of active interviews
                  </Col>
                  <Col xl="2" className="">
                    # of accepted offers
                  </Col>
                  <div className="total-interview-w">Total Interviews</div>
                  <div className="job-w">Added On</div>
                  <Col>
                    <i className="bx bx-cog font-size-17 align-middle mr-1"></i>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xl="12" className="">
                <div className="left_section">
                  {jobList.map((item, index) => {
                    return (
                      <Row key={index} className="mt-2 text-left">
                        <Col xl="12" className="pl-4 pr-0 text-left">
                          <Row
                            className="row-items green-text-color cursor-pointer"
                            onClick={() => {}}
                          >
                            <Col xl="6" className="">
                              <div className="font-size-16">{item.name}</div>
                              <div className="font-size-12"></div>
                            </Col>
                            <Col xl="6" className="">
                              {item.email}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="right_section">
                  <Row className="mt-2">
                    <Col xl="12" className="pl-4 pr-4 text-left">
                      <div className="noStudentw pl-2 pr-2">
                        <div className="text-center">
                          <p>
                            The students are yet to accept the invite and create
                            an account
                          </p>
                          <button
                            className="btn bg-green waves-effect waves-light w-md mr-2"
                            type="button"
                          >
                            Resend Invite
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(CollegeDashboard);
