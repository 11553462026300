import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Redux
import { withRouter, Link } from 'react-router-dom';
import { getAllUserData } from "../../../redux/actions/profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import user1 from '../../../assets/images/user.png';

// users

const ProfileMenu = (props) => {

   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);


   useEffect(() => {
    props.getAllUserData();
  }, []);
  
  return (
    <React.Fragment>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img
                            src={props.profile && props.profile.image_url ? props.profile.image_url : user1}
                            alt=""
                            height="20"
                            className="avatar-sm img-thumbnail rounded-circle"
                        />
                        {/* <img className="rounded-circle header-profile-user" src={default_company_logo} alt="Header Avatar" /> */}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a"  href="/profile"> <i className="bx bx-user font-size-16 align-middle mr-1"></i>Profile</DropdownItem>
                        {props.profile.login_type === "email" && (
                          <DropdownItem tag="a" href="/setting">
                          <i className="bx bx-cog font-size-17 align-middle mr-1"></i>Settings</DropdownItem>
                        )}
                        
                        <div className="dropdown-divider"></div>
                        <Link to="#" className="dropdown-item" onClick={()=>{
                            localStorage.removeItem('token');
                            props.history.push('/login')
                        }}>
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}

const mapStateToProps = (state) => {
    return {
      profile : {...state.profile.user},
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllUserData }, dispatch);
  };

export default withRouter( connect(mapStateToProps, mapDispatchToProps)(ProfileMenu));

