import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./rootsaga";
import auth from './auth';
// IMPORT ALL YOUR REDUCERS HERE
import layout from './layout';
import profile from './profile';
import setting from './accountsetting';

const appReducer = combineReducers({
    layout,
    auth,
    profile,
    setting
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const middleWares = [sagaMiddleware, thunk];

export const store = createStore(
    appReducer,
    composeEnhancers(applyMiddleware(...middleWares)),
)

// export default applyMiddleware(...middleWares)(createStore)(appReducer);

sagaMiddleware.run(rootSaga);
