import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col } from "reactstrap";
import SavedRows from '../../../components/SavedRows';
import AddEmpModal from './AddEmpModal';

const Employment = (props) =>{

    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    
    const editEmpRow = (row,type)=>{
      props.editEmpRow(row,type);
      tog_center(true);
    }

    const deleteEmpRow = (row) =>{
        props.deleteEmpRow(row);
    }

    const openModal = () => {
        props.setEmpDataNull();
        tog_center();
    }


    const createEmpRow = (data,type) =>{
        props.createEmpRow(data,type);
        tog_center(false);
    }

    return(
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="3" className="employment-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                Employment details
                            </h6>
                            <span className="font-size-18 pr-3 cursor-pointer" onClick={()=> { openModal()}}>
                                +   
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        {props.savedRowsEmp && props.savedRowsEmp.length > 0 && props.savedRowsEmp.map((data,index)=>{
                            return ( <SavedRows
                                data={data}
                                index={index}
                                editRow={editEmpRow}
                                deleteRow={deleteEmpRow}
                                type="employment"
                                key={index}
                            /> )
                        })  }
                    </Col>
                </Row>
            </TabPane>
            <AddEmpModal  
                modal_center={modal_center}
                centered={true}
                tog_center={()=>tog_center()}
                onModalSubmit={createEmpRow}
                empEditedData={props.empEditedData}
            />
        </TabContent>
    )
}

export default Employment;