import React,{ useState, useEffect } from 'react';
import { Container,Row, Col } from "reactstrap";
import logo from '../../assets/images/Zunavish_Logo_SVG.svg';
import "toastr/build/toastr.min.css";
import student_icon from '../../assets/images/student_icon.png';
import college_icon from '../../assets/images/college_icon.png';
import { withRouter } from 'react-router-dom';


const Dashboard = (props) =>{

    const [tabs, setTabs] = useState([{name:'Student',page:'student',route:'/studentOnboarding', img:student_icon,desc:' Looking for campus placements & updates'},
    {name:'Graduate',page:'graduate',route:'/studentOnboarding',img:college_icon,desc:'  Looking for job opportunities'}])

    function makeSelectedTab(index){
        let tempData = [...tabs];
        tempData.map((item,tindex)=>{
            if(tindex === index){
                item.selected = true;
            }else{
                item.selected = false;
            }
        })
        setTabs([...tempData]);
    }

    const continueButtonClick = ()=>{
        let tempd = [...tabs];
        let url = '';
        let page="";
        tempd.map((item)=>{
            if(item.selected){
                url = item.route;
                page= item.page;
            }
        });
        if(url) props.history.push({pathname: url ,  state: page });
    }
    

    return(
        <React.Fragment>
            <div className="page-content dashbord mt-0 p-0">
                <div className='logo-fixed'>
                    <img src={logo} alt='logo'></img>
                </div>
                <Container className="about-page-auth" fluid>
                    <Row>
                        <Col xs="12" xl="3" sm="3"></Col>
                        <Col xs="12" xl="6" sm="6" className="text-center">
                            <h5 className="mb-0 font-size-24">Welcome to Zunavish</h5>
                            <div className="mt-3">
                                <p className="font-size-14"> Tell us who you are to personalize your experience</p>
                            </div>
                            <div className='d-flex justify-content-center row'>
                                {tabs.map((item,index)=>{
                                    return(
                                        <div key={index} className={`col-xs-12 tabs mr-3 pt-4 ${item.selected ? 'selected' : ''} ${index==1 ? 'mobile_margin' : ''}`} onClick={()=>makeSelectedTab(index)}>
                                            <div>
                                                <img src={item.img}>
                                                </img>
                                            </div>
                                            <div className='mt-2'>
                                                {item.name}
                                            </div>
                                            <div className='mt-2'>
                                               {item.desc}
                                            </div>
                                        </div>
                                    )
                                })}
                               
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                                <button className="btn bg-green waves-effect waves-light w-md" type="button"
                                    onClick={()=> continueButtonClick()}
                                >Continue</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default  withRouter(Dashboard);