import React, { Component } from 'react';
import axios from 'axios';
import Loader from './loader';
import Common from '../../helpers/common';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

class LoadManager extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
    Common.setLoaderRef(this);
  }


  componentDidMount() {
    var numberOfAjaxCAllPending = 0;
    // Add a request interceptor
    axios.interceptors.request.use((config) => {
      // Do something before request is sent
      numberOfAjaxCAllPending++
      this.showLoader();
      const token = localStorage.getItem("token");
      config.headers.token =  token;
      return config;
    }, (error) => {
      numberOfAjaxCAllPending--;
      this.hideLoader();
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use((response) => {
      // Do something with response data
      numberOfAjaxCAllPending--;

      if (numberOfAjaxCAllPending == 0) {
        //hide loader
        this.hideLoader();
      }
      return response;
    }, (error) => {
      numberOfAjaxCAllPending--;
      this.hideLoader();
      // Do something with response error
      //console.log('errorerrorerrorerror',error.response);
      switch (error.response && error.response.status) {
        case 401:
          toastr.error(error.response.data.message, "", { timeOut: 2000 });
          break;
        case 404:
          toastr.error(error.response.data.message, "", { timeOut: 2000 });
          break;
        case 405:
          toastr.error(error.response.data.message, "", { timeOut: 2000 });
          break;
        default:
          break;
      }
      return Promise.reject(error);
    });
  }



  showLoader() {
    this.setState({ loading: true });
  }

  hideLoader() {
    this.setState({ loading: false });
  }
  //show={this.state.loading}

  render() {

    return (
      this.state.loading ? <Loader /> : ''

    )
  }



}



export default LoadManager;