import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Card, CardBody, Row, Col } from "reactstrap";
import classnames from "classnames";

const JobDetailTab = (props) => {
    return (
        <TabContent className="mt-3" activeTab={props.activeTab}>
            <TabPane tabId="1" className="detail-tab">
                <Row>
                    <Col lg="9">
                        <div className="font-size-18">Roles & Responsibilities</div>
                        <div className="mt-3 font-size-14">
                            {props.jobDetails && props.jobDetails.roles
                                ? props.jobDetails.roles
                                : "No description provided."}
                        </div>

                        <div className="border-bottom-dark mt-2 mb-3"> </div>

                        <div className="mt-3 font-size-18">Additional details related to the job</div>
                        <div className="mt-3 font-size-14">
                            {props.jobDetails && props.jobDetails.job_details
                                ? props.jobDetails.job_details
                                : "No description provided."}
                        </div>

                        <div className="border-bottom-dark mt-2 mb-3"> </div>

                        <div className="mt-3 font-size-18">Other details</div>
                        <div className="mt-3 font-size-14">
                            {props.jobDetails && props.jobDetails.perks
                                ? props.jobDetails.perks
                                : "No description provided."}
                        </div>
                    </Col>
                    <Col lg="3" className="mobile_margin_5">
                        <Card>
                            <CardBody>
                                <p className="green-text-color border-bottom pb-2 font-size-14 font-weight-medium">
                                    Job Details
                                </p>
                                <Row className="green-text-color">
                                    <Col sm={12}>
                                        <div className="mb-2">
                                            <i className="fas fa-graduation-cap fa-lg" />
                                            <span className="font-size-12 pl-2">
                                                {props.jobDetails && props.jobDetails.education_level}
                                            </span>
                                        </div>
                                        {/* <div className='mb-2'>
                                            <i className='mdi mdi-briefcase-outline font-size-22' />
                                            <span className="font-size-12 pl-2">
                                                4-8 years
                                            </span>
                                        </div> */}
                                        {/* <div className='mb-2'>
                                            <i className='mdi mdi-gender-male-female font-size-22' />
                                            <span className="font-size-12 pl-2">
                                                {props.jobDetails.diversity}
                                            </span>
                                        </div> */}
                                        {props.jobDetails && (props.jobDetails.min_exp || props.jobDetails.max_exp) && (
                                            <div className="mb-2">
                                                <i className="mdi mdi-briefcase-outline font-size-22" />
                                                {props.jobDetails.min_exp && props.jobDetails.max_exp && (
                                                    <span className="font-size-12 pl-2">
                                                        {props.jobDetails.min_exp}-{props.jobDetails.max_exp} years
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                {props.jobDetails &&
                                    ((props.jobDetails.skills_must && props.jobDetails.skills_must.length !== 0) ||
                                        (props.jobDetails.skills_good &&
                                            props.jobDetails.skills_good.length !== 0)) && (
                                        <p className="mt-2 green-text-color border-bottom pb-2 font-size-14 font-weight-medium">
                                            Skills{" "}
                                            {props.jobDetails &&
                                                props.jobDetails.skills_good &&
                                                props.jobDetails.skills_must && (
                                                    <span>
                                                        (
                                                        {props.jobDetails.skills_good.length +
                                                            props.jobDetails.skills_must.length}
                                                        )
                                                    </span>
                                                )}
                                        </p>
                                    )}
                                <Row className="green-text-color">
                                    {props.jobDetails &&
                                        props.jobDetails.skills_must &&
                                        props.jobDetails.skills_must.length > 0 && (
                                            <Col sm={12} className="mt-2">
                                                <p className="mb-2">Must have</p>
                                                <ul className="skills-learns pl-0 mb-0">
                                                    {props.jobDetails.skills_must.map((item, index) => {
                                                        return (
                                                            <li
                                                                className={classnames(
                                                                    "d-inline-block  ml-0 mr-2 mt-1 skills-items skill-container"
                                                                )}
                                                                key={index}
                                                            >
                                                                <span> {item} </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Col>
                                        )}
                                    {props.jobDetails &&
                                        props.jobDetails.skills_good &&
                                        props.jobDetails.skills_good.length > 0 && (
                                            <Col sm={12} className="mt-4">
                                                <p className="mb-2">Good to have</p>
                                                <ul className="skills-learns pl-0 mb-0">
                                                    {props.jobDetails.skills_good.map((item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={classnames(
                                                                    "d-inline-block mr-2 mt-1  ml-0 skills-items skill-container"
                                                                )}
                                                            >
                                                                {item}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Col>
                                        )}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
        </TabContent>
    );
};

export default JobDetailTab;
