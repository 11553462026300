import React, { useEffect, useState } from "react";
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";
import UploadFile from "../../../components/UploadFile";
import Common from "../../../helpers/common";

const CandidatureModal = (props) => {
  const [addLinksArray, setAddLinksArray] = useState(props.link);
  const [selectedFiles, setSelectedFiles] = useState([]);
  useEffect(() => {
    setAddLinksArray(props.link);
  }, [props]);

  const addLinkHander = () => {
    let tempArray = [...addLinksArray];
    let obj = { count: tempArray.length + 1 };
    tempArray.push(obj);
    setAddLinksArray(tempArray);
  };

  const linkHandler = (value) => {
    setAddLinksArray(value);
  };

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="candidature-upload-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Update Candidature Video
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mt-2">
          <Col lg="12">
            <FormGroup className="text-left mb-1">
              <Label for="basicpill-firstname-input1">
                {" "}
                Video Link{" "}
                <span className="font-weight-light">
                  (Time Limit: 5 minutes)
                </span>{" "}
              </Label>
              <Input
                value={addLinksArray}
                type="text"
                onChange={(e) => {
                  e.target.value = Common.trimStart(e.target.value);
                  linkHandler(e.target.value);
                }}
                placeholder="Add your video link here"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-green waves-effect waves-light"
          onClick={() => {
            props.onModalSubmit(addLinksArray);
          }}
        >
          Update{" "}
        </button>
      </div>
    </Modal>
  );
};

export default CandidatureModal;
