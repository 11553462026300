import React,{useEffect,useState} from 'react';
import { Container, Row, Col } from "reactstrap";
import {Link} from 'react-router-dom';
import user from '../../assets/images/user.png';
import axios from '../../config/axios';
import timeSince from "../../helpers/timeSinceFormatter";
const Notifications=()=>{
    const[notificationarray,setNotificationarray]=useState([]);
    useEffect(() => {
       axios.get('/api/notification', {headers: {
        token: `${localStorage.getItem("token")}`,
      }}).then(res=>{
        let reversearray=[];
        reversearray=res.data.notifications.reverse();
          setNotificationarray(reversearray)
        });
      }, []);
      function handleview(notification){
          if(!notification.read){
             axios.post('/api/notification/hasread', {id:notification.id},{headers: {
                token: `${localStorage.getItem("token")}`,
              }})
          }
      }
    // const notificationarray=[{src:scenery,time:"2 sec ago",message:"Your profile is incomplete and empty. This may reduce your visibility and hence we request you to update your details at the earliest.",redirec:"Complete profile"},
    // {src:briefcase,time:"12 min ago",message:"Zunavish has recommended 2 new jobs",redirec:"view jobs"},
    // {src:user,time:"22 min ago",message:"Thank you for your application for UX Designer at Fountane. We really appreciate your interest, but at the moment we find the requirments of the client for this particular role are different. We have your profile on our radar and we would reach out to you once we find an appropriate opportunity for you.",redirec:""},
    // {src:Zunavish,time:"32 min ago",message:"We have a few new updates sent to your email. In case of any issues please contact support@zunavish.com",redirec:""},
    // {src:scenery,time:"2 sec ago",message:"Your profile is incomplete and empty. This may reduce your visibility and hence we request you to update your details at the earliest.",redirec:"Complete profile"},
    // {src:briefcase,time:"12 min ago",message:"Zunavish has recommended 2 new jobs",redirec:"view jobs"},
    // {src:fortune,time:"12 min ago",message:"Thank you for your application for UX Designer at Fountane. We really appreciate your interest, but at the moment we find the requirments of the client for this particular role are different. We have your profile on our radar and we would reach out to you once we find an appropriate opportunity for you."},]
    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Row className="pt-3">
            <Col lg="9">
              <h4 className="notifications-heading mb-0"> Notifications </h4>
            </Col>
          </Row>
          <div className="notifications-container mt-3">
      {notificationarray[0]?notificationarray.map(notification=>{
          return(
              <Row className={`pt-3 underline-border pb-2 ${!notification.read?'green-back':""}`} onClick={()=>handleview(notification)}>
              <Col lg="10">
              <div className=" d-flex">
                  <div>
                      <img src={notification.image_url||user} style={{width:"30px",height:"30px",borderRadius:"50%"}}></img>
                  </div>
                  <div>
                      <div className="not-text px-2">{notification.message} </div>
                      <Link to={notification.link} className="not-button px-2 pt-3">{notification.type==="job_application_rejected"||notification.type==="job_application_selected"||notification.type==="job_application_hold"?'View Details':notification.type==="background_failure"||notification.type==="complete_profile"?"Complete Profile":""}</Link>
                  </div>
                  </div>
                   
              </Col>
              <Col>
              <div className="float-right notification-time">
          {timeSince(new Date(notification.createdAt))} ago
                      </div>  </Col>
                </Row>
            
          )
      }):""}
     
        
          </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Notifications;