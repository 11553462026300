import React,{ useState, useEffect } from 'react';

import { Row, Col, Media} from "reactstrap";

import JobDetailTab from './JobDetailsTab';
import ApiService from '../../../config/restapi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import default_company_logo from '../../../assets/images/d-company-logo.png';
import timeSince from '../../../helpers/timeSinceFormatter.js';


const JobDetails = (props) =>{

    const [activeTab, setactiveTab] = useState("1");
    const [menu, setMenu] = useState(false);
    const [jobDetails, setJobDetails] = useState({});

    function toggle(tab) {
		if (activeTab !== tab) {
			setactiveTab(tab);
		}
    }

    useEffect(() => {
        ApiService.getJobDetails(props.match.params.id).then((res) => {
            if(res && res.status === 200 && res.data){
                setJobDetails(res.data);
            }else{
                toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
        });
    },[]);

    function closeJobPost(){
        let Obj = {
            id : props.match.params.id,
            status : 'close'
        }
        // ApiService.updateJobStatus(Obj).then((res) => {
        //     if(res && res.status === 200 && res.data && res.data.success){
        //         toastr.success("Job Post Closed Successfully", "", { timeOut: 2000 });
        //         props.history.push('/jobdashboard');
        //     }else{
        //         toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        //     }
        // });
    }

    function openJobPost(){
        let Obj = {
            id : props.match.params.id,
            status : 'open'
        }
        // ApiService.updateJobStatus(Obj).then((res) => {
        //     if(res && res.status === 200 && res.data && res.data.success){
        //         toastr.success("Job Post Open Successfully", "", { timeOut: 2000 });
        //         props.history.push('/jobdashboard');
        //     }else{
        //         toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        //     }
        // });
    }

    function deleteDraft(){
        let Obj = {
            id : props.match.params.id
        }
        // ApiService.deleteJobPost(Obj).then((res) => {
        //     if(res && res.status === 200){
        //         toastr.success("Job Post Deleted Successfully", "", { timeOut: 2000 });
        //         props.history.push('/jobdashboard');
        //     }else{
        //         toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        //     }
        // });
    }

    return(
        <React.Fragment>
            <div className="page-content job-details">
                <Row>
                    <Col xl="12" className='pl-4 pr-4'>
                        <div className="top-container">
                            <div className="ux-designer">
                                {" "}
                                {jobDetails && jobDetails.foundJob && jobDetails.foundJob.title}
                            </div>
                            <div>
                                <img className="ml-4 shift-image rounded-circle avatar-md" src={default_company_logo} alt="Header Avatar" />
                            </div>
                            <div className="web_display justify-content-between company-info mobile_bottom_padd">
                                <div className="ml-5 px-4 green-text-color mobile_job_header web_display">
                                    <div className='mr-3 mt-2'>
                                        <div className="f-14 mb-2 px-2 ">
                                            <i className="far fa-building mr-1"></i>
                                            {jobDetails && jobDetails.foundJob && jobDetails.foundJob.company}
                                        </div>
                                        <div>
                                            <i className="far fa-clock mb-2 px-2"></i> 
                                            {jobDetails && jobDetails.foundJob && jobDetails.foundJob.posted_at ? timeSince(new Date(jobDetails.foundJob.posted_at)) + ' ago' : ''}
                                            {/* 2 minutes ago */}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='mt-2'>
                                            <i className="fas fa-map-marker-alt mb-2 mr-1 px-2"></i>
                                            {jobDetails && jobDetails.foundJob && jobDetails.foundJob.location}
                                        </div>
                                        <div className='d-block d-sm-none ml-2'>
                                            <span className='green-status-text'> {jobDetails && jobDetails.applicationStatus}</span>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div className='align-items-start mt-2 mr-2 d-none d-sm-block ml-2'>
                                   <span className='green-status-text'> {jobDetails && jobDetails.applicationStatus}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="12" className='pl-4 pr-4 accordin-open-container'> 
                        <div className='detail-list pl-4'>
                            <ul className='verti-timeline list-unstyled pl-0 font-size-14'>
                                    {jobDetails && jobDetails.applicationStatus === 'Not Applied' && 
                                    <li className='event-list d-flex'>
                                        <div className='hiding_div_top'></div>
                                        <div className="event-timeline-dot list-dot">
                                            {/* <i className="bx bx-right-arrow-circle font-size-18"></i> */}
                                        </div>
                                        {/* <div className='list-dot'> </div> */}
                                        <div className='hiding_div'></div>
                                        <Media>
                                            <Media body>
                                                <div>
                                                    Congrats! You have been <strong>shortlisted</strong>  as a potential candidate 
                                                    for the role {jobDetails && jobDetails.foundJob && jobDetails.foundJob.title}. You’ll receive updates and other details regarding the
                                                    interview on your email. <strong>All the best!</strong>
                                                </div>
                                            </Media>
                                        </Media>
                                    </li>}
                                    {jobDetails && jobDetails.applicationStatus === 'hold' && <li className='event-list d-flex'>
                                        <div className="event-timeline-dot list-dot">
                                            {/* <i className="bx bx-right-arrow-circle font-size-18"></i> */}
                                        </div>
                                        {/* <div className='list-dot'> </div> */}
                                        <Media>
                                            <Media body>
                                                <div>
                                                    Your application put <strong>on hold. </strong>Follow this thread to get more updates on your interview.
                                                </div>
                                            </Media>
                                        </Media>
                                    </li>}
                                    {jobDetails && jobDetails.applicationStatus === 'select' && <li className='event-list d-flex'>
                                        <div className="event-timeline-dot list-dot">
                                            {/* <i className="bx bx-right-arrow-circle font-size-18"></i> */}
                                        </div>
                                        {/* <div className='list-dot'> </div> */}
                                        <div className='hiding_div'></div>
                                        <Media>
                                            <Media body>
                                                <div>
                                                    Your application has been accepted by the company. You’ll receive more updates regarding the offer on your registered email address                                                                    
                                                </div>
                                            </Media>
                                        </Media>
                                    </li>}
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col xl="12" className='pl-4 pr-4'> 
                        <JobDetailTab jobDetails={jobDetails && jobDetails.foundJob } activeTab={activeTab}/>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default  JobDetails;