import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import cloudOutline from "../../../assets/images/cloud-outline.svg";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import TextArea from "../../../components/TextArea";
import ApiService from "../../../config/restapi";
import { Designation } from "../../../helpers/constant_variables";
import Select from "react-select";
import Common from "../../../helpers/common";

const CompanyDetails = (props) => {
  const [web_urlcharcomplete, setWeb_urlcharcomplete] = useState(0);
  const [compnayLogoImg, setCompnayLogoImg] = useState(null);

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "jpeg" || ext === "png" || ext === "gif" || ext === "jpg") {
      return true;
    }
    return false;
  }

  function handleAcceptedFiles(files) {
    if (validateFileExtention(files)) {
      const formData = new FormData();
      formData.append("file", files[0]);

      ApiService.uploadLogo(formData).then((res) => {
        if (res && res.status === 200 && res.data) {
          setCompnayLogoImg(res.data.link);
          props.OnchangeCallBack(res.data.link, "company_logo");
        }
      });
    } else {
      toastr.error("Please Upload Valid Format", "", { timeOut: 2000 });
    }
  }

  function onChangeTextareaHandler(event) {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(event.target.value);
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) setWeb_urlcharcomplete(WordCount);
    props.OnchangeCallBack(event.target.value, "websiteurl");
  }

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="company-details">
        <Form>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">Designation </Label>
                <Select
                  className="select-container"
                  value={props.details.designationObj}
                  onChange={(selectedOption) =>
                    props.OnchangeCallBack(selectedOption, "designation")
                  }
                  options={Designation}
                  isSearchable
                  placeholder="Select"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">College Name </Label>
                <Input
                  type="text"
                  value={props.details.name || ""}
                  className="form-control"
                  maxLength="40"
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "name")
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-4 text-left">
                <Label>
                  College Logo{" "}
                  <span className="font-size-11 text-muted"> (Optional) </span>
                </Label>
                {compnayLogoImg && (
                  <div className="company-logo-img">
                    <img src={compnayLogoImg} alt="" className="" />
                    <span
                      className="img-close"
                      onClick={() => setCompnayLogoImg(null)}
                    >
                      &times;
                    </span>
                  </div>
                )}
                {!compnayLogoImg && (
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <div className=" needsclick" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="needsclick upldoad-logo">
                            <button
                              type="button"
                              className="d-flex btn w-lg btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                            >
                              {" "}
                              <img
                                className=""
                                src={cloudOutline}
                                alt="cloud"
                              />
                              <div className="font-size-11 ml-2">
                                {" "}
                                UPLOAD PHOTO
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2"> College Location</Label>
                <Input
                  type="select"
                  value={props.details.location || ""}
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "city")
                  }
                  name="select"
                  id="exampleCity"
                >
                  <option>Select</option>
                  <option>Mumbai</option>
                  <option>Delhi</option>
                  <option>Bangalore</option>
                  <option>Hyderabad</option>
                  <option>Ahmedabad</option>
                  <option>Chennai</option>
                  <option>Kolkata</option>
                  <option>Surat</option>
                  <option>Pune</option>
                  <option>Jaipur</option>
                  <option>Lucknow</option>
                  <option>Kanpur</option>
                  <option>Nagpur</option>
                  <option>Indore</option>
                  <option>Thane</option>
                  <option>Bhopal</option>
                  <option>Visakhapatnam</option>
                  <option>Pimpri-Chinchwad</option>
                  <option>Patna</option>
                  <option>Vadodara</option>
                  <option>Ghaziabad</option>
                  <option>Ludhiana</option>
                  <option>Agra</option>
                  <option>Nashik</option>
                  <option>Faridabad</option>
                  <option>Meerut</option>
                  <option>Rajkot</option>
                  <option>Kalyan-Dombivali</option>
                  <option>Vasai-Vira</option>
                  <option>Varanasi</option>
                  <option>Srinagar</option>
                  <option>Aurangabad</option>
                  <option>Dhanbad</option>
                  <option>Amritsar</option>
                  <option>Navi Mumbai</option>
                  <option>Allahabad</option>
                  <option>Ranchi</option>
                  <option>Howrah</option>
                  <option>Coimbatore</option>
                  <option>Jabalpur</option>
                  <option>Gwalior</option>
                  <option>Vijayawada</option>
                  <option>Jodhpur</option>
                  <option>Madurai</option>
                  <option>Raipur</option>
                  <option>Kota</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0 text-left">
                <TextArea
                  labelName="Website URL"
                  isOptional={true}
                  onChangeTextareaHandler={(event) =>
                    onChangeTextareaHandler(event)
                  }
                  maxLength="500"
                  isCharLimitShow={true}
                  charlimitLength="500"
                  charComplete={web_urlcharcomplete}
                  value={props.details.website_url}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default CompanyDetails;
