import React from "react";
import { Redirect } from "react-router-dom";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
//Components
import Dashboard from '../pages/Dashboard';
import SetPassword from "../pages/Authentication/SetPassword";
import Login from "../pages/Authentication/Login";
import StudentProfile from '../pages/Student/Profile';
import AccountSetting from '../pages/AccountSetting';
import SignUP from '../pages/Authentication/SignUp';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import Verification from "../pages/Authentication/Verification";
import ResetVerification from "../pages/Authentication/ResetVerification";
import Notifications from "../pages/Notifications/Notifications";
//Student Components
import AboutYourself from '../pages/Student/AboutYourself';
import StudentDashboard from '../pages/Student/StudentDashboard';
import StudentJobDetails from '../pages/Student/JobDetails';
//College Components 
import CollegeProfile from '../pages/College/Profile';
import CollegeOnboarding from '../pages/College/CollegeOnborading';
import CollegeDashboard from '../pages/College/CollegeDashboard';


const userRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/profile", component: StudentProfile },
    { path: "/setting", component: AccountSetting },
    { path: "/studentOnboarding", component: AboutYourself },
    { path: "/studentDashboard", component: StudentDashboard },
    { path: "/studentJobDetail/:id", component: StudentJobDetails },
    {path:"/notifications",component:Notifications},
    { path: "/collegeProfile", component: CollegeProfile },
    { path: "/collegeOnboarding", component: CollegeOnboarding },
    { path: "/collegeDashboard", component: CollegeDashboard },
    // this route should be at the end of all other routes
    // () => localStorage.getItem("token") ? <Redirect to="/dashboard" /> : <Redirect to="/dashboard" /> }
    { path: "/", exact: true, component: ()=> localStorage.getItem("token") ? <Redirect to="/studentDashboard" /> : <Redirect to="/login" />}
    
];

const authRoutes = [
    {path:"/setpassword",component:SetPassword},
    {path:"/login",component:Login},
    {path:"/forgotpassword",component: ForgotPassword},
    {path:"/verification",component: Verification},
    { path: "/passwordreset", component: ResetVerification },

    //Authentication
    // { path: "/login", component: Login },
    { path: "/signup", component: SignUP },
    {path:"/linkedin/callback" , component:LinkedInPopUp}, 
]

export { userRoutes, authRoutes };