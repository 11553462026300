import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import PersonalDetails from "./PersonalDetails";
import EducationalDetails from "./EducationalDetails";
import EmployementDetails from "./EmployementDetails";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ApiService from "../../../config/restapi";
import getAge from "../../../helpers/getAge";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Common from "../../../helpers/common";
const AboutYourselfWizard = (props) => {
  //Personal Details States

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [dobDate, setDobDate] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [Marital, setMarital] = useState();
  const [children, setChildren] = useState();
  const [nchild, setnchild] = useState(0);
  const [city, setcity] = useState();
  const [age, setAge] = useState();
  const [about, setabout] = useState();
  const [country, setCountry] = useState();
  const [profileData, setProfileData] = useState();
  const [countrycode, setCountryCode] = useState();

  const [educationSaveData, setEducationSaveData] = useState([]);

  const [employmentSaveData, setEmploymentSaveData] = useState([]);

  const wizardHeadersNames = [
    { name: "Personal details", key: "pd-width" },
    { name: "Educational details", key: "ed-width" },
  ];

  // { name: "Employment details", key: "empd-width" }]

  const [wizardHeaders, setwizardHeaders] = useState([]);
  const [activeTab, setactiveTab] = useState(1);

  function checkname(name) {
    if (name === "" || !name) {
      return { message: "Name should not be blank", valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid name", valid: false };
    }
  }

  function checkDob(age) {
    if (age) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your date of birth", valid: false };
    }
  }

  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkCity(value) {
    if (value === "" || !value || value === "Select") {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  const OnchangeCallBack = (value, type) => {
    console.log(value, type);
    switch (type) {
      case "name":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "mobile":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setMobileNumber(value);
        break;
      case "Marital":
        setMarital(value);
        break;
      case "Children":
        setChildren(value === "Yes" ? true : false);
        if (value === "No") {
          setnchild(0);
        }
        break;
      case "noofChildren":
        setnchild(value);
        break;
      case "city":
        if (value === "Select") {
          setcity("");
        } else {
          setcity(value);
        }
        break;
      case "age":
        setAge(value);
        break;
      case "about":
        let newValue = Common.trimStart(value.replace(/[^\w\s\.\,]/gi, ""));
        setabout(newValue);
        break;
      case "dob":
        setDobDate(value);
        setAge(getAge(value));
        break;
      case "country":
        setCountry(value);
        break;
      case "dropDownCountry":
        setMobileNumber("");
        break;
      case "isd_code":
        setCountryCode(value);
        break;
      default:
        break;
    }
  };

  const onSkipClick = (tab) => {
    if (tab === 1) {
      setactiveTab(2);
    } else if (tab === 2) {
      props.onCompleteAboutYourselfCallBack();
      //setactiveTab(3);
    }
    // else if (tab === 3) {
    //     props.onCompleteAboutYourselfCallBack();
    // }
  };

  const onNextClick = (tab) => {
    if (tab === 1) {
      let Obj = {
        name,
        email,
        age,
        city,
        country: "India",
        has_children: children,
        children_count: nchild ? Number(nchild) : 0,
        phone: mobileNumber,
        marital_status: Marital,
        bio: about,
        date_of_birth: dobDate,
        isd_code: countrycode,
      };

      if (
        !checkname(name).valid ||
        !email ||
        !checkmobileNumber(mobileNumber).valid ||
        !checkDob(age).valid ||
        !checkCity(city).valid
      ) {
        if (name === null) setName("");
        if (city === null) setcity("");
        if (country === null) setCountry("");
        if (mobileNumber === null) setMobileNumber("");
        if (age === null) setAge("");
        toastr.error("Please enter all the required information.");
        return;
      }
      //  setactiveTab(2);
      //   if(mobileNumber.length===10&&mobileNumber[0]>=6){
      ApiService.saveOnboardingPersonalDetails(Obj).then((res) => {
        if (res && res.status === 200 && res.data) {
          setactiveTab(2);
        }
      });
      //   }
      //   else{
      //     toastr.error("Enter a valid 10 digit mobile number", "", { timeOut: 2000 });
      //   }
    } else if (tab === 2) {
      if (educationSaveData.length > 0) {
        ApiService.saveEducationDetailsOnBoarding(educationSaveData).then(
          (res) => {
            if (res && res.status === 200 && res.data) {
              props.onCompleteAboutYourselfCallBack();
            }
          }
        );
      } else {
        props.onCompleteAboutYourselfCallBack();
      }
    } else if (tab === 3) {
      // if(employmentSaveData.length > 0){
      //     ApiService.saveEmploymentDetailsOnBoarding(employmentSaveData).then((res) => {
      //         if(res && res.status === 200 && res.data){
      //             props.onCompleteAboutYourselfCallBack();
      //         }
      //     });
      // }else{
      //     props.onCompleteAboutYourselfCallBack();
      // }
      props.onCompleteAboutYourselfCallBack();
    }
  };

  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "" && obj[key] != false)
        return false;
    }
    return true;
  }

  const deleteFromBackendData = (updatedData) => {
    // if(index > -1){
    //     console.log('educationSaveData',index,educationSaveData);
    //     const tempERow =  [...educationSaveData];
    //     tempERow.splice(index, 1);
    setEducationSaveData([...updatedData]);
    //}
  };

  const onNextClickEduDataCallBack = (eduData, editIndex) => {
    if (!checkProperties(eduData[0])) {
      if (editIndex > -1) {
      } else {
        // const latestData =  [...eduData, ...rowData];
        setEducationSaveData([...eduData]);
      }
    }
  };

  function validateEducationName() {
    let tempData = [...educationSaveData];
    let booleanAraay = [];
    // tempData.map((item)=>{
    //     if(item.college){
    //         booleanAraay
    //     }
    // });
  }

  const deleteEmpFromBackendData = (updatedData) => {
    setEmploymentSaveData([...updatedData]);
  };

  const onNextClickEmploymentCallBack = (empData, editIndex) => {
    if (!checkProperties(empData[0])) {
      if (editIndex > -1) {
      } else {
        // const latestData =  [...empData, ...rowData];
        setEmploymentSaveData([...empData]);
      }
    }
  };

  function getProfileData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.data) {
        console.log(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        setMobileNumber(res.data.phone);
        setMarital(res.data.marital_status);
        setChildren(res.data.has_children);
        setnchild(res.data.children_count);
        setcity(res.data.city);
        setAge(res.data.age);
        setabout(res.data.bio);
        setDobDate(new Date(res.data.date_of_birth));
        setCountry(res.data.country);
        setProfileData({ ...res.data });
        setCountryCode(res.data.isd_code);
      }
    });
  }

  useEffect(() => {
    getProfileData();
    let temData = [...wizardHeadersNames];
    temData.map((obj, index) => {
      if (activeTab === index + 1) {
        obj["active"] = "active";
        obj["key"] = temData[index].key;
      } else {
        delete obj.active;
        delete obj.key;
      }
      if (activeTab === 2 || activeTab === 3) {
        if (activeTab === 3)
          temData[activeTab - 3]["makeactive"] = activeTab - 3;
        temData[activeTab - 2]["makeactive"] = activeTab - 2;
        temData[activeTab - 2]["lessWidthD"] = "less-width-d";
      }
    });
    setwizardHeaders([...temData]);
  }, [activeTab]);

  const ToggleHeaderTab = (tab, item) => {
    if ("makeactive" in item) {
      setactiveTab(item.makeactive + 1);
    }
  };

  const WizardHeaders = (item1, idx) => {
    return (
      <NavItem key={idx}>
        <NavLink className={`text-center ${item1.active ? item1.active : ""}`}>
          <span
            onClick={() => {
              ToggleHeaderTab(activeTab, item1);
            }}
            className={classnames(
              "step-number mr-1",
              { [`${item1.key}`]: item1.key },
              { [`${item1.lessWidthD}`]: item1.lessWidthD },
              { [`${item1.solidImage}`]: item1.solidImage }
            )}
          >
            {idx + 1}
          </span>
          {item1.name ? (
            <div className={classnames("font-size-14 mt-2")}>
              {" "}
              {item1.name}{" "}
            </div>
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div id="basic-pills-wizard" className="twitter-bs-wizard">
      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
        {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
      </ul>
      <Card>
        <CardBody>
          {activeTab === 1 ? (
            <PersonalDetails
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              mobileNumber={mobileNumber}
              name={name}
              email={email}
              marital={Marital}
              age={age}
              about={about}
              city={city}
              children={children}
              nchild={nchild}
              dobDate={dobDate}
              country={country}
              countrycode={countrycode}
            />
          ) : activeTab === 2 ? (
            <EducationalDetails
              activeTab={activeTab}
              onNextClickEduDataCallBack={onNextClickEduDataCallBack}
              deleteFromBackendData={deleteFromBackendData}
              getSavedRows={profileData && profileData.educations}
              updateEducationData={getProfileData}
            />
          ) : (
            <EmployementDetails
              activeTab={activeTab}
              onNextClickEmploymentCallBack={onNextClickEmploymentCallBack}
              deleteEmpFromBackendData={deleteEmpFromBackendData}
              getSavedRows={profileData && profileData.experiences}
              // updateEmpData={getProfileData}
            />
          )}
        </CardBody>
      </Card>
      <div className="text-right">
        {activeTab === 1 && (
          <button
            className={classnames(
              "btn btn-outline-grey waves-effect waves-light w-lg mr-2"
            )}
            type="submit"
            disabled={
              !checkname(name).valid ||
              !email ||
              !checkmobileNumber(mobileNumber).valid ||
              !checkDob(age).valid ||
              !checkCity(city).valid
                ? true
                : false
            }
            onClick={() => onSkipClick(activeTab)}
          >
            Skip
          </button>
        )}

        {activeTab === 1 && (
          <button
            className="btn bg-green waves-effect waves-light w-lg"
            type="submit"
            onClick={() => onNextClick(activeTab)}
          >
            Next
          </button>
        )}

        {activeTab === 2 && (
          <button
            className={classnames(
              "btn btn-outline-grey waves-effect waves-light w-lg mr-2"
            )}
            type="submit"
            disabled={
              props.location && props.location.state === "student"
                ? profileData &&
                  profileData.educations &&
                  profileData.educations.length > 0
                  ? false
                  : true
                : false
            }
            onClick={() => onSkipClick(activeTab)}
          >
            Skip
          </button>
        )}

        {activeTab === 2 && (
          <button
            className="btn bg-green waves-effect waves-light w-lg"
            type="submit"
            disabled={
              props.location && props.location.state === "student"
                ? profileData &&
                  profileData.educations &&
                  profileData.educations.length > 0
                  ? false
                  : true
                : false
            }
            onClick={() => onNextClick(activeTab)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default withRouter(AboutYourselfWizard);
