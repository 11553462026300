import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DateRangePicker from "../../../components/DateRangePicker";
import Common from "../../../helpers/common";
const DegreeDropDownData = [
  {
    value: 1,
    label: "B.Tech/B.E.",
    courses: [
      { label: "Civil Engineering", value: 12 },
      { label: "Electrical Engineering", value: 12 },
      { label: "Mechanical Engineering", value: 12 },
      { label: "Computer Science Engineering", value: 12 },
      { label: "Artificial Intelligence", value: 12 },
      { label: "Electronics and Communications Engineering", value: 12 },
      { label: "Chemical Engineering", value: 12 },
      { label: "Marine Engineering", value: 12 },
      { label: "Metallurgical Engineering", value: 12 },
      { label: "Petroleum Engineering", value: 12 },
      { label: "Oher", value: 12 },
    ],
  },
  {
    value: 2,
    label: "B.A",
    courses: [
      { label: "BA English", value: 12 },
      { label: "BA English Literature", value: 12 },
      { label: "BA Psychology", value: 12 },
      { label: "BA History", value: 12 },
      { label: "BA Economics", value: 12 },
      { label: "BA Sociology", value: 12 },
      { label: "BA Political Science", value: 12 },
      { label: "BA Journalism and Mass Communication", value: 12 },
      { label: "BA LLB", value: 12 },
      { label: "Other", value: 12 },
    ],
  },
  {
    value: 3,
    label: "BSc",
    courses: [
      { label: "BSc Agriculture", value: 12 },
      { label: "BSc Biotechnology", value: 12 },
      { label: "BSc Zoology", value: 12 },
      { label: "BSc Clinical Research & Healthcare Management", value: 12 },
      { label: "BSc Microbiology", value: 12 },
      { label: "BSc Nursing", value: 12 },
      { label: "B.Sc.Physiotherapy", value: 12 },
      { label: "B.Sc.Radiology", value: 12 },
      { label: "B.Sc.Physics", value: 12 },
      { label: "B.Sc.Chemistry", value: 12 },
      { label: "B.Sc.Botany", value: 12 },
      { label: "B.Sc.IT", value: 12 },
      { label: "B.Sc.Computer Science", value: 12 },
      { label: "Oher", value: 12 },
    ],
  },
  {
    value: 4,
    label: "B.Com",
    courses: [
      { label: "B.Com(Economics)", value: 12 },
      { label: "B.Com(Accounting & Finance)", value: 12 },
      { label: "B.Com(Financial Markets)", value: 12 },
      { label: "B.Com(Banking & Insurance)", value: 12 },
      { label: "B.Com(Taxation)", value: 12 },
      { label: "B.com Banking and Insurance", value: 12 },
      { label: "Corporate Accountancy", value: 12 },
      { label: "Advertising and Brand Management", value: 12 },
      { label: "Principles and Practice of Accountancy", value: 12 },
      { label: "Oher", value: 12 },
    ],
  },
];

const AddEducationalModal = (props) => {
  const [college, setCollege] = useState();
  const [degree, setDegree] = useState();
  const [course, setCourse] = useState();
  const [sDate, setSDate] = useState();
  const [eDate, setEDate] = useState();
  const [stillStudying, setStillStudying] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState("");

  useEffect(() => {
    if (props.educationEditedData) {
      // console.log("edit",props.educationEditedData);
      setCollege(props.educationEditedData.college);
      setDegree({
        ...degree,
        label: props.educationEditedData.degree,
      });
      setCourse({
        ...course,
        label: props.educationEditedData.course,
      });
      setSDate(
        props.educationEditedData.start_year
          ? new Date(props.educationEditedData.start_year)
          : ""
      );
      // props.educationEditedData.start_year
      setEDate(
        props.educationEditedData.grad_year
          ? new Date(props.educationEditedData.grad_year)
          : ""
      );
      //props.educationEditedData.grad_year
      setStillStudying(props.educationEditedData.still_studying);
      setDateDisabled(props.educationEditedData.still_studying);
    } else {
      clearData();
    }
  }, [props.educationEditedData]);

  function clearData() {
    setCollege();
    setDegree({});
    setCourse({});
    setSDate();
    setEDate();
    setStillStudying(false);
  }

  const handleChange = (selectedOption) => {
    setDegree(selectedOption);
    setCourseList([...selectedOption.courses]);
    setCourse({});
  };

  const handleCourseChange = (selectedOption) => {
    setCourse(selectedOption);
  };

  function handleSaveButton(type) {
    let Valid;
    if (college) {
      var RegEx = /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/;
      Valid = RegEx.test(college);
      if (!Valid) {
        setInvalidFormat("Numbers are not allowed.");
      }
      console.log(Valid);
    }
    if (!Valid || !college || !degree || !sDate || !(stillStudying || eDate)) {
      setShowError(true);
      return;
    }
    if (type == "edit") {
      props.onModalSubmit(
        {
          college,
          degree: degree.label,
          course: course.label,
          sDate,
          eDate,
          stillStudying,
          id: props.educationEditedData.id,
        },
        "edit"
      );
    } else if (type == "save") {
      props.onModalSubmit(
        {
          college,
          degree: degree.label,
          course: course.label,
          sDate,
          eDate,
          stillStudying,
        },
        "save"
      );
      clearData();
    }
  }

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="add-education"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          {props.educationEditedData ? "Edit" : "Add"} Educational Details
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>
                School/College<span className="label-required"> * </span>
              </Label>
              <Input
                maxLength="40"
                type="text"
                value={college || ""}
                onChange={(e) => {
                  e.target.value = Common.trimStart(
                    e.target.value.replace(/[^a-zA-Z\s]/gi, "")
                  );
                  setCollege(e.target.value);
                  if (e.target.value.trim() === "") {
                    setInvalidFormat("College name shouldn't be blank");
                  } else {
                    setInvalidFormat("");
                  }
                }}
              />
              {(showError || college !== undefined) && !college && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  College name cannot be blank.
                </label>
              )}
              {invalidFormat && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {invalidFormat ? invalidFormat : ""}
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>
                Degree<span className="label-required"> * </span>
              </Label>
              <Select
                className="select-container"
                value={degree}
                onChange={handleChange}
                options={DegreeDropDownData}
                isSearchable
                innerProps={{ className: "test" }}
                placeholder="Select"
              />
              {(showError || degree !== undefined) && !degree && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Choose a degree from the list.
                </label>
              )}

              {/* <Input type="select" name="select" value={degree} onChange={(e) => setDegree(e.target.value)}>
                                <option>Select</option>
                                {DegreeDropDownData.map((item,index)=>{
                                    return (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </Input>       */}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>
                Course<span className="label-required"> * </span>
              </Label>
              <Select
                className="select-container"
                value={course}
                onChange={handleCourseChange}
                options={courseList}
                isSearchable
                innerProps={{ className: "test" }}
                placeholder="Select"
              />
              {(showError || degree !== undefined) && !degree && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  Choose a degree from the list.
                </label>
              )}

              {/* <Input type="select" name="select" value={degree} onChange={(e) => setDegree(e.target.value)}>
                                <option>Select</option>
                                {DegreeDropDownData.map((item,index)=>{
                                    return (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    )
                                })}
                            </Input>       */}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="mb-0 text-left">
              <Label>
                Start Year<span className="label-required"> * </span>
              </Label>
              <InputGroup>
                <DateRangePicker
                  proptype="start"
                  propsref="startcalendar"
                  propsid="startyear"
                  selected={sDate}
                  onChange={(date) => setSDate(date)}
                  selectsStart
                  startDate={sDate}
                  endDate={sDate}
                  maxDate={eDate}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showDisabledMonthNavigation
                />
                <label htmlFor="startyear">
                  <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                </label>
                {(showError || sDate !== undefined) && !sDate && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    Start year cannot be blank.
                  </label>
                )}
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-0 text-left">
              <Label>
                End Year<span className="label-required"> * </span>
              </Label>
              <InputGroup>
                <DateRangePicker
                  proptype="end"
                  propsref="endcalendar"
                  propsid="endyear"
                  className="form-control"
                  selected={eDate}
                  onChange={(date) => setEDate(date)}
                  selectsEnd
                  startDate={sDate}
                  endDate={eDate}
                  minDate={sDate}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showDisabledMonthNavigation
                  readOnly={dateDisabled}
                />
                <label htmlFor="endyear">
                  {" "}
                  <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>{" "}
                </label>
              </InputGroup>
              {(showError || eDate !== undefined) &&
                !eDate &&
                !dateDisabled && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    End year cannot be blank.
                  </label>
                )}
              <FormGroup className="pt-2" check>
                <Label className="main" check>
                  <Input
                    type="checkbox"
                    check={
                      stillStudying
                        ? stillStudying.toString()
                        : false.toString()
                    }
                    onChange={(event) => {
                      setStillStudying(event.target.checked);
                      if (event.target.checked) {
                        setEDate("");
                        setDateDisabled(true);
                      } else {
                        setDateDisabled(false);
                      }
                    }}
                  />{" "}
                  I'm still studying
                  <span className="geekmark"></span>
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        {props.educationEditedData ? (
          <button
            type="button"
            disabled={
              !college ||
              !degree ||
              !course ||
              !sDate ||
              !(stillStudying || eDate)
            }
            className="btn bg-green waves-effect waves-light"
            onClick={() => {
              handleSaveButton("edit");
            }}
          >
            Save{" "}
          </button>
        ) : (
          <button
            type="button"
            className="btn bg-green waves-effect waves-light"
            onClick={() => {
              handleSaveButton("save");
            }}
          >
            Save{" "}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default AddEducationalModal;
