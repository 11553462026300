import React, { useState, useEffect } from 'react';
import {  Modal, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const DegreeDropDownData = [
    {
      "value": 1,
        "label": "B.Tech/B.E.",
        "courses": [
            {label:"Civil Engineering",                           value:12},    
            {label:"Electrical Engineering",                       value:12},          
            {label:"Mechanical Engineering", value:12},
            {label:"Computer Science Engineering",                 value:12},        
            {label:"Artificial Intelligence",                      value:12},        
            {label:"Electronics and Communications Engineering",   value:12},          
            {label:"Chemical Engineering",                         value:12},      
            {label:"Marine Engineering",                           value:12}, 
            {label:"Metallurgical Engineering",                    value:12},   
            {label:"Petroleum Engineering",                        value:12}, 
           
           
           
           
           
           
           
           
           

      ]
    },
    {
      "value": 2,
        "label": "B.Sc",
        "courses":[
        {
          value:1,
          gid:2,
          label:'MS/M.Sc(Science)'
        },
        {
          value:2,
          gid:2,
          label:'MBA/PGDM'
        },
          {
          value:3,
          gid:2,
          label:'M.A'
        }
      ]
    },
    {
      "value": 3,
        "label": "B.A",
       "courses":[
        {
          value:1,
          gid:3,
          label:'Ph.D/Doctorate'
        },
        {
          value:2,
          gid:3,
          label:'MPHIL'
        }
      ]
    },
    {
        "value": 4,
        "label": "B.Com",
        "courses": [
            {
                value: 1,
                gid: 3,
                label: 'Ph.D/Doctorate'
            },
            {
                value: 2,
                gid: 3,
                label: 'MPHIL'
            }
        ]
    },

]

const DegreeDetailModal = (props) => {

    const [college, setCollege] = useState();
    const [degree, setDegree] = useState();
    const [course, setCourse] = useState();
    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();
    const [stillStudying, setStillStudying] = useState(false);
    const [dateDisabled, setDateDisabled] = useState(false);
    const [courseList, setCourseList] = useState([]);

    const handleCourseChange = selectedOption => {
        setCourse(selectedOption);
    };

    const handleChange = selectedOption => {
        setDegree(selectedOption);
        setCourseList([...selectedOption.courses]);
       // setCourse();
    };

    function clearData(){
        setCollege();
        setDegree({});
        setCourse({});
    }


    return (
        <Modal
            isOpen={props.modal_center}
            centered={props.centered}
            className='add-education'
            size="lg"
        >
            <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                    {props.educationEditedData ? "Edit" : "Add" } Degree Details
                </h6>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) } }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">   
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label>Degree</Label>
                            <Select
                                className="select-container"
                                value={degree}
                                onChange={handleChange}
                                options={DegreeDropDownData}
                                isSearchable 
                                innerProps={{className:"test"}}
                                placeholder="Select"
                            />
                        </FormGroup>
                    </Col>
                </Row>   
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="basicpill-colg-input1">Course</Label>
                            <Select
                                className="select-container multiple-select"
                                value={course}
                                onChange={handleCourseChange}
                                options={courseList}
                                isSearchable 
                                innerProps={{className:"test"}}
                                placeholder="Select"
                                isMulti={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row >
                    <Col lg="6">
                        <FormGroup>
                            <Label>Total students registered for the degree</Label>
                            <Input type="number" value={college || ''} onChange={(e) => setCollege(e.target.value)} />
                        </FormGroup>
                    </Col>
                </Row>          
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn bg-green waves-effect"
                    data-dismiss="modal"
                >
                    Close
                </button>
                {props.educationEditedData ? 
                        <button
                            type="button"
                            className="btn bg-green waves-effect waves-light"
                            onClick={()=> { props.onModalSubmit({college,degree:degree.label,course:course.label,sDate,eDate,stillStudying,id : props.educationEditedData.id},"edit");} }
                        >Save </button>
                    :
                    <button
                        type="button"
                        className="btn bg-green waves-effect waves-light"
                        onClick={()=> { props.onModalSubmit({college,degree:degree.label,course:course.label,sDate,eDate,stillStudying},"save"); clearData();} }
                    >Save </button>
                }
            </div>
        </Modal>
      );
}

export default DegreeDetailModal;