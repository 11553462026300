export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';
export const USER_DETAILS_ERROR = 'USER_DETAILS_DETAIL';

const initialState = {
    loading: true,
    error: null,
    user: {},
}

const user = (state = initialState, action) => {
    switch (action.type) {
        
        case USER_DETAILS:
            state = { loading: false, error: null, user: { ...action.payload } }
            break;
        case USER_DETAILS_LOADING:
            state = { ...state, loading: true };
            break;
        case USER_DETAILS_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        default:
            break;
    }
    return state;
}
export default user;