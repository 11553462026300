import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import default_company_logo from "../../../assets/images/d-company-logo.png";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import cloudOutline from "../../../assets/images/cloud-outline.svg";
import ApiService from "../../../config/restapi";
import Select from "react-select";
import { Domains } from "../../../helpers/constant_variables";
import Common from "../../../helpers/common";

const CompnaySizes = [
  {
    value: 1,
    label: "0-10 employees",
    modifyName: "Start up/ Small Enterprise",
  },
  {
    value: 2,
    label: "10-50 employees",
    modifyName: "Mid sized",
  },
  {
    value: 3,
    label: "50-200+ employees",
    modifyName: "Large scale/ Global",
  },
];

const EditProfileModal = (props) => {
  const [country, setCountry] = useState("IN");
  const [fname, setFName] = useState();
  const [industry, setIndustry] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [size, setSize] = useState();
  const [location, setLocation] = useState();
  const [url, setUrl] = useState();
  const [profilepic, setProfilepic] = useState(null);
  const [companySizes, setCompanySizes] = useState(CompnaySizes);
  const [isdCode, setIsdCode] = useState();

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "jpeg" || ext === "png" || ext === "gif" || ext === "jpg") {
      return true;
    }
    return false;
  }

  function handleAcceptedFiles(files) {
    if (validateFileExtention(files)) {
      const formData = new FormData();
      formData.append("file", files[0]);

      ApiService.uploadLogo(formData).then((res) => {
        if (res && res.status === 200 && res.data) {
          setProfilepic(res.data.link);
        }
      });
    } else {
      toastr.error("Please Upload Valid Photo", "", { timeOut: 2000 });
    }
  }

  function clearData() {}

  const removeProfilePic = () => {
    setProfilepic(null);
  };

  const OnchangeHandler = (value, type) => {
    switch (type) {
      case "fname":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setFName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "mobileNumber":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setMobileNumber(value);
        break;
      case "size":
        setSize(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "url":
        setUrl(value);
        break;
      case "industry":
        setIndustry(value);
        break;
      case "isd_code":
        setIsdCode(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.editedData) {
      setFName(props.editedData.name);
      setEmail(props.editedData.email);
      setMobileNumber(props.editedData.number);
      setProfilepic(props.editedData.logo_url);
      setSize(props.editedData.size);
      setLocation(props.editedData.location);
      setUrl(props.editedData.URL);
      setIndustry({ label: props.editedData.industry });
      makeCompanySizeSelected(props.editedData.size);
      setIsdCode(props.editedData.isd_code);
    }
  }, [props.editedData]);

  function makeCompanySizeSelected(size) {
    let tempSizes = [...companySizes];
    tempSizes.map((item) => {
      if (item.label === size) {
        item["selected"] = "selected";
      }
    });
  }

  function companySizeSelect(item, index) {
    let tempData = [...companySizes];
    tempData.map((item, idx) => {
      if (index === idx) {
        item["selected"] = "selected";
        setSize(item.label);
      } else {
        delete item.selected;
      }
    });
    setCompanySizes([...tempData]);
  }
  const savechanges = () => {
    if (mobileNumber.length === 10 && mobileNumber[0] >= 6) {
      props.onModalSubmit(
        {
          name: fname,
          email,
          number: mobileNumber,
          size,
          industry: industry.label,
          location,
          URL: url,
          logo_url: profilepic,
          id: props.editedData.id,
          isd_code: isdCode,
        },
        "edit"
      );
      clearData();
    } else {
      toastr.error("Please Enter A Valid Mobile Number");
    }
  };
  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      size="lg"
      className="edit-profile-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Edit College Profile
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>College Logo</Label>
              <div className="d-flex">
                <div className="profile-logo-container">
                  <img
                    src={profilepic ? profilepic : default_company_logo}
                    alt=""
                    className="avatar-md img-thumbnail rounded-circle"
                  />
                </div>
                <div className="upload-section">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="font-size-12">
                            <button
                              className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                              type="button"
                            >
                              <img src={cloudOutline} alt="cloud" />{" "}
                              <span className="pl-1">UPLOAD PHOTO</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {profilepic && (
                    <div className="pt-1 pl-1">
                      <button
                        className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                        type="button"
                        onClick={() => removeProfilePic()}
                      >
                        <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required" />
                        <span className="pl-1 label-required">
                          REMOVE PHOTO{" "}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">College Name</Label>
              <Input
                type="text"
                value={fname || ""}
                maxLength="40"
                disabled={true}
                onChange={(e) => OnchangeHandler(e.target.value, "fname")}
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Email Address </Label>
              <Input
                type="email"
                value={email || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "email")}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">Phone Number </Label>
              <Row>
                <Col lg="3" className="pr-0">
                  <select
                    className="form-control phone-number-select"
                    value={isdCode || ""}
                    onChange={(event) => {
                      OnchangeHandler(event.target.value, "isd_code");
                    }}
                  >
                    {getCountries().map((country) => (
                      <option key={country} value={country}>
                        {country} {getCountryCallingCode(country)}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg="9">
                  {/* <PhoneInput
                    country={country ? country : "IN"}
                    international
                    value={mobileNumber}
                    onChange={(v) => OnchangeHandler(v, "mobileNumber")}
                    className="form-control phone-number-input"
                  /> */}
                  <Input
                    type="tel"
                    maxLength={10}
                    value={mobileNumber}
                    onChange={(e) =>
                      OnchangeHandler(e.target.value, "mobileNumber")
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">College Location</Label>
              {/* <Input
                type="text"
                value={location || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "location")}
              /> */}
              <Input
                type="select"
                value={location || ""}
                onChange={(event) =>
                  OnchangeHandler(event.target.value, "location")
                }
                name="select"
                id="exampleCity"
              >
                <option>Select</option>
                <option>Mumbai</option>
                <option>Delhi</option>
                <option>Bangalore</option>
                <option>Hyderabad</option>
                <option>Ahmedabad</option>
                <option>Chennai</option>
                <option>Kolkata</option>
                <option>Surat</option>
                <option>Pune</option>
                <option>Jaipur</option>
                <option>Lucknow</option>
                <option>Kanpur</option>
                <option>Nagpur</option>
                <option>Indore</option>
                <option>Thane</option>
                <option>Bhopal</option>
                <option>Visakhapatnam</option>
                <option>Pimpri-Chinchwad</option>
                <option>Patna</option>
                <option>Vadodara</option>
                <option>Ghaziabad</option>
                <option>Ludhiana</option>
                <option>Agra</option>
                <option>Nashik</option>
                <option>Faridabad</option>
                <option>Meerut</option>
                <option>Rajkot</option>
                <option>Kalyan-Dombivali</option>
                <option>Vasai-Vira</option>
                <option>Varanasi</option>
                <option>Srinagar</option>
                <option>Aurangabad</option>
                <option>Dhanbad</option>
                <option>Amritsar</option>
                <option>Navi Mumbai</option>
                <option>Allahabad</option>
                <option>Ranchi</option>
                <option>Howrah</option>
                <option>Coimbatore</option>
                <option>Jabalpur</option>
                <option>Gwalior</option>
                <option>Vijayawada</option>
                <option>Jodhpur</option>
                <option>Madurai</option>
                <option>Raipur</option>
                <option>Kota</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Website URL{" "}
                <span className="font-size-11 text-muted font-weight-ligt">
                  (Optional)
                </span>
              </Label>
              <Input
                type="text"
                value={url || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "url")}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          className="btn bg-green waves-effect waves-light"
          onClick={() => {
            savechanges();
          }}
        >
          Save{" "}
        </button>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
