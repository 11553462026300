import React, { useState, useEffect } from "react";
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";
import UploadFile from "../../../components/UploadFile";
import ApiService from "../../../config/restapi";

const ResumeModal = (props) => {
  const [addLinksArray, setAddLinksArray] = useState([{ count: 1 }]);
  const [selectedFiles, setSelectedFiles] = useState();

  const addLinkHander = () => {
    let tempArray = [...addLinksArray];
    let obj = { count: tempArray.length + 1 };
    tempArray.push(obj);
    setAddLinksArray(tempArray);
  };
  function uploadResumeCallback(f) {
    const formData = new FormData();
    formData.append("file", f);
    ApiService.uploadJobResume(formData).then((res) => {
      if (res && res.data) {
        ApiService.editProfileData({
          resume_url: res.data.data.Location,
        }).then((res) => {});
      }
    });
  }

  function removeResumeFile() {
    if (props.user.resume_url) {
      ApiService.editProfileData({ resume_url: "" }).then((res) => {});
    }
  }

  const linkHandler = (value, idx) => {
    let tempCopy = [...addLinksArray];
    if (idx > -1) tempCopy[idx]["value"] = value;
    setAddLinksArray(tempCopy);
  };

  const getSelectedFile = (files) => {
    setSelectedFiles([...files]);
  };

  const onCloseResumeCancel = () => {
    setSelectedFiles();
  };

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="resume-upload-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Upload Resume
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <p className="font-weight-medium mb-2">Upload file</p>
            <UploadFile
              label="Upload"
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
            />
          </Col>
        </Row>
        {selectedFiles && selectedFiles.length > 0 && (
          <Row className="mb-2 border-bottom pb-2 mt-3">
            <Col lg="12">
              <span>{selectedFiles[0].name}</span>
              <button
                type="button"
                onClick={() => onCloseResumeCancel()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Col>
          </Row>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-green waves-effect waves-light"
          disabled={!selectedFiles}
          onClick={() => {
            const formData = new FormData();
            formData.append("file", selectedFiles);
            props.onModalSubmit(formData, "save");
          }}
        >
          Upload{" "}
        </button>
      </div>
    </Modal>
  );
};

export default ResumeModal;
