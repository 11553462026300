import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import moment from "moment";
import DateRangePicker from "../../../components/DateRangePicker";
import Common from "../../../helpers/common";

const PersonalDetails = (props) => {
  console.log(props);
  const [charComplete, setCharComplete] = useState(0);
  const [country, setCountry] = useState();

  const handleTextarea = (event) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) {
      setCharComplete(WordCount);
      return true;
    } else {
      return false;
    }
  };
  function checkname(name) {
    if (name === "" || !name) {
      return { message: "Name should not be blank", valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid name", valid: false };
    }
  }

  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkCity(value) {
    if (value === "" || !value || value === "Select") {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkDob(age) {
    if (age) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your date of birth", valid: false };
    }
  }

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="personal-details">
        <Form>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">
                  Name<span className="label-required"> * </span>{" "}
                </Label>
                <Input
                  maxLength="40"
                  type="text"
                  value={props.name || ""}
                  className="form-control"
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "name")
                  }
                />
                {props.name !== null && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {checkname(props.name).message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="mb-4 text-left">
                <Label>D.O.B</Label>
                <InputGroup>
                  <DateRangePicker
                    propsref="birthcalendar"
                    proptype="birth"
                    propsid="dobpicker"
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    className="form-control"
                    selected={props.dobDate || ""}
                    onChange={(val) => props.OnchangeCallBack(val, "dob")}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                  />
                  <label htmlFor="dobpicker">
                    <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                  </label>
                </InputGroup>
                {props.age !== null && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {checkDob(props.age).message}
                  </label>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="cust-age-width text-left">
                <Label for="basicpill-lastname-input2">Age</Label>
                <Input
                  type="text"
                  value={`${props.age || `NA`}` + " Years " || ""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Email <span className="label-required"> * </span>
                </Label>
                <Input
                  type="email"
                  disabled={true}
                  value={props.email || ""}
                  onChange={(event) => {
                    event.target.value = Common.trimStart(event.target.value);
                    props.OnchangeCallBack(event.target.value, "email");
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">Phone Number </Label>
                <Row>
                  <Col lg="5" xs="5" className="pr-0">
                    <select
                      className="form-control phone-number-select"
                      value={props.countrycode || ""}
                      onChange={(event) => {
                        props.OnchangeCallBack(event.target.value, "isd_code");
                      }}
                    >
                      {getCountries().map((country) => (
                        <option key={country} value={country}>
                          {country} +{getCountryCallingCode(country)}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col lg="7" xs="7">
                    {/* <PhoneInput
                                            country={country ? country : 'IN'}
                                            international
                                            value={props.mobileNumber || ''}
                                            onChange={(value)=> props.OnchangeCallBack(value, 'mobile')} 
                                            className="form-control phone-number-input"
                                        /> */}
                    <Input
                      type="tel"
                      maxLength={10}
                      value={props.mobileNumber || ""}
                      onChange={(e) =>
                        props.OnchangeCallBack(e.target.value, "mobile")
                      }
                    />
                  </Col>
                  {props.mobileNumber !== null && (
                    <label
                      style={{
                        "margin-bottom": "0",
                        color: "red",
                        padding: "0px 12px",
                      }}
                    >
                      {checkmobileNumber(props.mobileNumber).message}
                    </label>
                  )}
                </Row>
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
                        <Col lg="12" >
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Marital Status</Label>
                                <FormGroup check>
                                    <Label  className="cust-radio-label" check>
                                        <Input type="radio"  checked={props.marital === 'Single' ? true : false} name="radio1" value="Single" onChange={(value)=>props.OnchangeCallBack(value.target.value, 'Marital')} />{' '}
                                        Single
                                    </Label>
                                    <Label  className="cust-radio-label" check>
                                        <Input type="radio" checked={props.marital === 'Married' ? true : false} name="radio1" value="Married" onChange={(value)=>props.OnchangeCallBack(value.target.value, 'Marital')}/>{' '}
                                        Married
                                    </Label>
                                    <Label  className="cust-radio-label" check>
                                        <Input type="radio"  checked={props.marital === 'I prefer not to share' ? true : false} name="radio1" value="I prefer not to share" onChange={(value)=>props.OnchangeCallBack(value.target.value, 'Marital')}/>{' '}
                                        I prefer not to share
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                    </Row>         */}
          {/* <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="6">
                                    <FormGroup className="text-left">
                                        <Label for="basicpill-lastname-input2">Children</Label>
                                        <FormGroup check>
                                            <Label className="cust-radio-label" check>
                                                <Input type="radio" checked={props.children?true:false} name="radio2" value="Yes" onChange={(value)=>props.OnchangeCallBack(value.target.value, 'Children')}/>{' '}
                                                Yes
                                            </Label>
                                            <Label check>
                                                <Input type="radio" checked={!props.children?true:false} name="radio2" value="No" onChange={(value)=>props.OnchangeCallBack(value.target.value, 'Children')} />{' '}
                                                No
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                                <Col lg="6"> 
                                    <FormGroup className="text-left">
                                        <Label for="exampleSelect">No. of Children</Label>
                                        <Input disabled={!props.children? true : false} value={props.nchild || ''} onChange={(event) => props.OnchangeCallBack(event.target.value, 'noofChildren')}  type="select" name="select" >
                                            <option>Select</option>
                                           <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </Input>
                                    </FormGroup>                                            
                                </Col>
                            </Row>                                            
                        </Col>
                    </Row>         */}
          <Row>
            {/* <Col lg="6">
                            <FormGroup className="text-left">
                                <Label>Country</Label>
                                <Input type="select" name="select" value={props.country || ''} onChange={(event) => props.OnchangeCallBack(event.target.value, 'country')} >
                                    <option>Select</option>
                                    <option>India</option>
                                    <option>United Sates</option>                                                   
                                </Input>
                            </FormGroup>
                        </Col> */}
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">City/District</Label>
                <Input
                  type="select"
                  value={props.city || ""}
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "city")
                  }
                  name="select"
                  id="exampleCity"
                >
                  <option>Select</option>
                  <option>Mumbai</option>
                  <option>Delhi</option>
                  <option>Bangalore</option>
                  <option>Hyderabad</option>
                  <option>Ahmedabad</option>
                  <option>Chennai</option>
                  <option>Kolkata</option>
                  <option>Surat</option>
                  <option>Pune</option>
                  <option>Jaipur</option>
                  <option>Lucknow</option>
                  <option>Kanpur</option>
                  <option>Nagpur</option>
                  <option>Indore</option>
                  <option>Thane</option>
                  <option>Bhopal</option>
                  <option>Visakhapatnam</option>
                  <option>Pimpri-Chinchwad</option>
                  <option>Patna</option>
                  <option>Vadodara</option>
                  <option>Ghaziabad</option>
                  <option>Ludhiana</option>
                  <option>Agra</option>
                  <option>Nashik</option>
                  <option>Faridabad</option>
                  <option>Meerut</option>
                  <option>Rajkot</option>
                  <option>Kalyan-Dombivali</option>
                  <option>Vasai-Vira</option>
                  <option>Varanasi</option>
                  <option>Srinagar</option>
                  <option>Aurangabad</option>
                  <option>Dhanbad</option>
                  <option>Amritsar</option>
                  <option>Navi Mumbai</option>
                  <option>Allahabad</option>
                  <option>Ranchi</option>
                  <option>Howrah</option>
                  <option>Coimbatore</option>
                  <option>Jabalpur</option>
                  <option>Gwalior</option>
                  <option>Vijayawada</option>
                  <option>Jodhpur</option>
                  <option>Madurai</option>
                  <option>Raipur</option>
                  <option>Kota</option>
                </Input>
                {props.city !== null && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {checkCity(props.city).message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0 text-left">
                <Label for="basicpill-lastname-input2">
                  Tell us about yourself
                </Label>
                <Input
                  placeholder="Let others know what type of person you are"
                  type="textarea"
                  value={props.about || ""}
                  charlimitLength="500"
                  onChange={(event) => {
                    if (handleTextarea(event)) {
                      props.OnchangeCallBack(event.target.value, "about");
                    }
                  }}
                  name="text"
                />
                <div className="text-right">
                  <span className="font-size-12 font-weight-light">
                    {" "}
                    {charComplete}/500{" "}
                  </span>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default PersonalDetails;
