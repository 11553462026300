import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "reactstrap";
import TextArea from "../../../components/TextArea";
import Common from "../../../helpers/common";

const EditBioModal = (props) => {
  const [biovalue, setBiovalue] = useState();
  const [bioCharComplete, setBioCharComplete] = useState();

  const onChangeTextareaHandler = (event) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(event.target.value);
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) {
      setBiovalue(event.target.value);
      setBioCharComplete(WordCount);
    }
  };

  useEffect(() => {
    if (props.editedData.val) {
      var regex = /\s+/gi;
    
    var WordCount =
    props.editedData.val === ""
        ? 0
        : props.editedData.val.trim().replace(regex, " ").split(" ").length;
      setBiovalue(props.editedData.val);
      setBioCharComplete(WordCount);
    }
  }, [props.editedData]);

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="edit-bio-modal"
    >
      <div className="modal-header">
        <div className="modal-title mt-0 font-weight-bold" id="myModalLabel">
          Edit Bio
        </div>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="">
          <Col lg="12">
            <TextArea
              labelName="Tell us about yourself"
              onChangeTextareaHandler={(event) =>
                onChangeTextareaHandler(event)
              }
              isCharLimitShow={true}
              charlimitLength="500"
              value={biovalue}
              charComplete={bioCharComplete}
              placeholder="Let others know what type of person you are"
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-green waves-effect waves-light"
          onClick={() => props.onModalSubmit(biovalue)}
        >
          Save Changes
        </button>
      </div>
    </Modal>
  );
};

export default EditBioModal;
