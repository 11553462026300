import React, { useState, useEffect } from 'react';
import { TabContent,TabPane, Form, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import 'react-phone-number-input/style.css'
import PhoneInput,  { getCountries , getCountryCallingCode } from 'react-phone-number-input/input'

const ContactInfo = (props) =>{

    const [country, setCountry] = useState('IN');

    return(
        <TabContent activeTab={props.activeTab} className="twitter-bs-wizard-tab-content">
            <TabPane tabId={3} className="employment-details text-left">
                <Form>
                <Row>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Email</Label>
                                <Input type="email"  value={props.details.email || ''} onChange={(event)=> props.OnchangeCallBack(event.target.value, 'email')}  />
                                <div className='font-size-12 font-weight-light mt-2'>
                                    (please provide head recruiter’s email address)
                                </div>
                           </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Phone Number </Label>
                                <Row>
                                    <Col lg="4" className="pr-0">
                                        <select
                                            className="form-control phone-number-select"
                                            value={props.details.isd_code || ''}
                                            onChange={event => { props.OnchangeCallBack(event.target.value, 'isd_code') }}>
                                            {getCountries().map((country) => (
                                                <option key={country} value={country}>
                                                    {country} {getCountryCallingCode(country)}
                                                </option>
                                            ))}
                                        </select>   
                                    </Col>
                                    <Col lg="8">
                                        {/* <PhoneInput
                                            country={country ? country : 'IN'}
                                            international
                                            value={props.details.number || ''}
                                            onChange={(value)=> props.OnchangeCallBack(value, 'mobile')} 
                                            className="form-control phone-number-input"
                                        /> */}
                                        <Input type="tel" maxLength={10} 
                                            value={props.details.number || ''} 
                                            onChange={(e)=> props.OnchangeCallBack(e.target.value, 'mobile')} 
                                        />
                                    </Col>
                                </Row>                                             
                            </FormGroup>
                        </Col>
                    </Row>       
                </Form>
            </TabPane>
        </TabContent>
    )
}

export default ContactInfo;