import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Label, InputGroup, InputGroupAddon } from "reactstrap";
import { GoogleLoginButton } from "../../components/AuthButton/Google";
import { LinkedInLoginButton } from "../../components/AuthButton/LinkedIn";

// Redux

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";

// actions
import { loginUser, loginwithGoogle, loginwithLinkedin } from "../../redux/actions/auth";

import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";
import logo from "../../assets/images/Zunavish_text.svg";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [elementtype, setElementType] = useState("password");

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        //props.loginUser(values, props.history);
        props.loginUser(email, password, props.history);
    }

    function handlePasswodType(type) {
        let newType = type === "text" ? "password" : "text";
        setElementType(newType);
    }
    function responseGoogle(response) {
        const tokenid = response.tokenObj.id_token;
        props.loginwithGoogle(tokenid, props.history);
    }
    function responseLinkedin(response) {
        console.log(response);
        props.loginwithLinkedin(response.code, props.history);
        // const tokenid = response.tokenObj.id_token;
        // props.signupWithGoogle(tokenid, props.history);
    }
    return (
        <React.Fragment>
            <div className="account-pages">
                <div className="float-left left-section">
                    <Row className="w-100">
                        <Col md={12} lg={12}>
                            <div className="px-2 pb-2">
                                <h5 className="font-size-36 font-weight-bold login-heading">
                                    Login to <img src={logo} alt="" height="35" className="pb-2" />
                                </h5>
                            </div>
                            <div className="px-2">
                                <AvForm
                                    className="form-horizontal login-form"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v);
                                    }}
                                >
                                    {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
                                    {/* <AvField
                    name="email"
                    label="Email"
                    placeholder="Eg:john@gmail.com"
                    type="email"
                    required
                    onChange={({ target }) => {
                      setEmail(target.value);
                    }}
                  /> */}
                                    <AvGroup>
                                        <Label for="email" className="green-text-color">
                                            Email
                                        </Label>
                                        <InputGroup>
                                            <AvInput
                                                name="email"
                                                type="email"
                                                placeholder="Eg: john@gmail.com"
                                                required
                                                onChange={({ target }) => {
                                                    setEmail(target.value);
                                                }}
                                            />

                                            <AvFeedback>Invalid email.</AvFeedback>
                                        </InputGroup>
                                    </AvGroup>

                                    <AvGroup>
                                        <div className="d-flex justify-content-between">
                                            <Label for="password" className="green-text-color">
                                                Password
                                            </Label>
                                            {/* <Label> <Link to="/forgotpassword" className="font-weight-semibold green-text-color">Forgot Password?</Link></Label> */}
                                        </div>

                                        <InputGroup>
                                            <AvInput
                                                name="password"
                                                type={elementtype}
                                                placeholder="Enter Password"
                                                required
                                                onChange={({ target }) => {
                                                    setPassword(target.value);
                                                }}
                                                style={{ "paddingRight": "2rem" }}
                                            />
                                            <InputGroupAddon
                                                onClick={() => handlePasswodType(elementtype)}
                                                addonType="append"
                                            >
                                                {elementtype === "text" ? (
                                                    <i className="mdi mdi-eye-off-outline font-size-20"></i>
                                                ) : (
                                                    <i className="mdi mdi-eye-outline font-size-20"></i>
                                                )}
                                            </InputGroupAddon>
                                            <AvFeedback>Password is required.</AvFeedback>
                                        </InputGroup>
                                        <div className="pt-1">
                                            <Link className="text_light_green" to="/forgotpassword">
                                                Forgot Password?
                                            </Link>
                                        </div>
                                    </AvGroup>

                                    <div className="mt-3 text-left pb-3 border-bottom">
                                        <button
                                            className="btn bg-green waves-effect waves-light w-lg mr-2"
                                            type="submit"
                                        >
                                            Log In
                                        </button>
                                        <span className="mr-2" style={{ color: " #97A4B0", fontSize: "12px" }}>
                                            Or
                                        </span>
                                        <GoogleLoginButton onSuccess={responseGoogle} />
                                        <LinkedInLoginButton onSuccess={responseLinkedin} />
                                    </div>

                                    <div className="mt-2 text-left">
                                        <span className="text-color-light roboto-light text-muted">
                                            New to ZUNAVISH?{"  "}
                                        </span>
                                        <Link to="/signup" className="font-weight-semibold sign-in-link">
                                            Sign up
                                        </Link>
                                    </div>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="float-left right-section d-none d-sm-block">
                    <div className="text-container">
                        <div className="company-text-bold">
                            <img src={text_logo} alt="text" />
                        </div>
                        <div className="mt-2 text-center">{/* vivify your business and People */}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStatetoProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};
const mapDispatchToProps = {
    loginUser,
    loginwithGoogle,
    loginwithLinkedin,
};
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Login));
